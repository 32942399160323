import React, { useState } from 'react';
import './admin-login.styles.scss';
import { Button, Form, Modal } from 'react-bootstrap';
import catStoreLogo from '../../../../assets/cat-store-main-logo.png';
import { firestore } from '../../../../firebase/firebase.utils';


const AdminLogin = ({ setIsLoggedIn, setWhichAdmin }) => {
    const [emailId, setEmailId] = useState('');
    const [password1, setPassword1] = useState('');
    // const [password2, setPassword2] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // State to Handle reset password
    const [code, setcode] = useState('');
    const [newPass1, setNewPass1] = useState('');
    const [newPass2, setNewPass2] = useState('');

    // const csEmail = 'superadmin';
    // const csPassword = 'catstore@997765';

    // HANDLE LOGIN
    const handleLogin = async () => {
        // const docRefUser = await firestore.collection("admins");
        // await docRefUser.get()
        //     .then((doc) => {
        //         if (emailId === doc.data().userName && password1 === doc.data().password) {
        //             localStorage.setItem('loggedIn', true);
        //             localStorage.setItem('store', 'cat-main');
        //             setWhichAdmin('cat-main');
        //             return setIsLoggedIn(true);
        //         } else {
        //             alert("Wrong Credentials")
        //         }
        //     })


            const adminRef = await firestore.collection("admins");
            adminRef.get().then((snapshot) => {
              

                snapshot.docs.map(async (doc) => {
                    if (emailId === doc.data().userName && password1 === doc.data().password) {
                        localStorage.setItem('loggedIn', true);
                        localStorage.setItem('store', 'cat-main');
                        setWhichAdmin('cat-main');
                        return setIsLoggedIn(true);
                    } else {
                        alert("Wrong Credentials")
                    }
                });
            });
    }

    // Handle Reset
    const handleReset = async () => {
        let randomCode = '';
        let adminEmail = '';
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const docRefUser = await firestore.collection("stores").doc("cat-store-user-profile");
        for (var i = 16; i > 0; --i) randomCode += chars[Math.floor(Math.random() * chars.length)];
        await docRefUser.get().then((doc) => adminEmail = doc.data().userEmail);
        await docRefUser.update({ resetCode: randomCode })
            .then(async () => await firestore.collection("confirmationMail").add({
                to: `${adminEmail}`,
                message: {
                    subject: "Password Reset Code",
                    text: `The reset code is ${randomCode}!`,
                    html: `<div style="display: flex; justify-content: center;">
                        <img src="https://catkodagu.com/static/media/cat-store-main-logo.bf2b649c.png" alt="cat-store-logo" width="80px">
                        </div>
                        <div>
                            <span>
                                <h3>Please use the below code to reset your password </h3> <br> <h2>Code: ${randomCode}</h2> <br>
                            </span>
                        </div>
                        `,
                },
            })
            )
            .then(async () => await handleShow())
    }

    // Handle Passsword Change From Modal
    const handlePasswordChange = async () => {
        let resetCode = '';
        const docRefUser = await firestore.collection("stores").doc("cat-store-user-profile");
        await docRefUser.get().then((doc) => resetCode = doc.data().resetCode);
        if (code !== resetCode) {
            alert('Please Check The 16 Digit Reset Code')
        } else if (newPass1 !== newPass2) {
            alert('Passwords Do not Match Please Check Again')
        } else {
            await docRefUser.update({ password: newPass1 })
                .then(async () => await docRefUser.update({ resetCode: 'bf2b649c17643jbd' }))
                .then(async () => await alert('Password Changed Please Login Now'))
                .then(async () => await handleClose())
                .then(async () => await window.location.reload(false))
        }
    }

    return (
        <div className='admin-login-main-container'>
            <img src={catStoreLogo} width='100px' alt='cat-admin-logo' />
            <Form className='admin-login-form'>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="email" placeholder="Enter Username" onChange={(event) => setEmailId(event.target.value)} />
                    {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(event) => setPassword1(event.target.value)} />
                </Form.Group>
                <Button variant={'success'} onClick={() => handleLogin()}>Login</Button><br />
                <a className='login-form-forgot-password' href="#forgot-password" onClick={() => handleReset()}>Forgot Password?</a>
            </Form>
            <span className='copyright-stamp-admin'>Try Logging in to the admin panel <b>ONLY</b> if you are <b>AUTHORIZED</b></span>
            <span className='copyright-stamp-admin'>© Admin Panel Powered By CatStore</span>

            {/* Reset Modal */}
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <h3>Reset Password</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Text className="reset-instructions">
                            <ul>
                                <li>Please Check Your Admin Email For a 16 Digit Alphanumeric Code.</li>
                                <li>Input / Paste the code below.</li>
                                <li>If an email with the code was not triggered within 10 minutes, please click on the close button and try again. </li>
                            </ul>
                        </Form.Text>
                        <Form.Label>16 Digit Code</Form.Label>
                        <Form.Control type="text" id="formBasicCode" placeholder="16 Digit Code" onChange={(event) => setcode(event.target.value)} /><br />
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" id="formBasicpwd" placeholder="Password" onChange={(event) => setNewPass1(event.target.value)} /><br />
                        <Form.Label>Re-type Password</Form.Label>
                        <Form.Control type="password" id="formBasicpwd2" placeholder="Re-type Password" onChange={(event) => setNewPass2(event.target.value)} /><br />
                        <Button variant={'success'} onClick={() => handlePasswordChange()}>Change Password</Button>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminLogin;