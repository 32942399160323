import React, { useEffect, useState } from "react";
import "./admin-orders.styles.scss";
import { Table, Button, Nav, Form } from "react-bootstrap";
import { firestore } from "../../../../firebase/firebase.utils";

const SuperAdminOrders = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [selectedPill, setSelectedPill] = useState("new-orders");
  const [searchTerm, setSearchTerm] = useState("");

  // effect to load in orders
  useEffect(() => {
    const getOrderItems = async () => {
      let orderArray = [];
      const orderItemDoc = await firestore
        .collection("online-store")
        .doc("online-orders")
        .collection("orders")
        .orderBy("orderTime", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.map((doc) => {
            orderArray.push(doc.data());
          });
        });
      setAllOrders(orderArray);
    };
    getOrderItems();
  }, []);

  // Handle Order Status Change
  const handleOrderStatus = async (event, id) => {
    console.log(event.target.value);
    if (event.target.value) {
      const dataToSave = { orderStatus: event.target.value };

      const docRefupdate = await firestore
        .collection("online-store")
        .doc("online-orders")
        .collection("orders");
      docRefupdate.get().then(async (snapshot) => {
        let docId = "";
        await snapshot.docs.map((doc) =>
          doc.data().orderId === id ? (docId = doc.id) : null
        );
        await firestore
          .collection("online-store")
          .doc("online-orders")
          .collection("orders")
          .doc(`${docId}`)
          .update({
            ...dataToSave,
          })
          .then(async () => await alert("Document edited successfully"))
          .then(async () => await window.location.reload(false));
      });
    } else {
      alert("Please Select A Value");
    }
  };

  return (
    <div className="admin-orders-main-container">
      <h1>ORDERS</h1>
      <div className="admin-orders-counters">
        <div className="admin-count-box">
          <h6>Total Orders</h6>
          <span className="box-count">{allOrders.length}</span>
        </div>
      </div>
      <br />
      <h5>Manage Orders</h5>
      <div className="admin-orders-status-tabs">
        <Nav variant="tabs" defaultActiveKey="#new-orders">
          <Nav.Item>
            <Nav.Link
              eventKey="#new-orders"
              onClick={() => setSelectedPill("new-orders")}
            >
              New Orders
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="#in-transit"
              onClick={() => setSelectedPill("in-transit")}
            >
              In-Transit
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="#delivered"
              onClick={() => setSelectedPill("delivered")}
            >
              Delivered
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="#cancelled"
              onClick={() => setSelectedPill("cancelled")}
            >
              Cancelled
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {/* New Orders */}
        {selectedPill === "new-orders" ? (
          <div className="tabbed-table">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Order Placed On</th>
                  <th>Cust. Name</th>
                  <th>Cust. Phone</th>
                  <th>Cust. Email</th>
                  <th>Cust. Full Address</th>
                  <th>Order Placed From</th>
                  <th>Current Status</th>
                  <th>Order Details</th>
                  <th>Order Total</th>
                  <th>Payment Status</th>
                  <th>Change Status</th>
                </tr>
              </thead>
              <tbody>
                {allOrders.map((order, i) => (
                  <>
                    {order.orderStatus === "orderPlaced" ? (
                      <tr>
                        <td>{order.orderId}</td>
                        <th>
                          {`${order.orderTime.toDate().getDate()}
                                                        /${order.orderTime
                                                          .toDate()
                                                          .getMonth()}
                                                        /${order.orderTime
                                                          .toDate()
                                                          .getFullYear()}`}
                        </th>
                        <td>{order.customerDetails.fullName}</td>
                        <td>{order.customerDetails.customerPhone}</td>
                        <td>{order.customerDetails.customerEmail}</td>
                        <td width="150px">
                          {order.customerDetails.fullAddress}
                        </td>
                        <td>{order.orderPlacedFrom}</td>
                        <td>{order.orderStatus}</td>
                        <td>
                          <div className="table-inner-order-items">
                            <Table responsive bordered striped>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Quantity</th>
                                  <th>Store</th>
                                  <th>Price / Unit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {order.orderItems.map((item, i) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>
                                      {order.orderPlacedFrom !== "b2b form"
                                        ? item.quantity
                                        : item.count}
                                    </td>
                                    <td>{item.parentStore}</td>
                                    <td>{item.price} INR</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </td>
                        <td>{order.tAmount}</td>
                        <td>
                          <td>
                            {order.paymentInfo.razorpayOrderId
                              ? order?.paymentInfo?.razorpayOrderId
                              : "COD"}
                          </td>
                          <td>{order?.paymentInfo?.razorpayPaymentId}</td>
                        </td>
                        <td>
                          <Form>
                            <Form.Control
                              size="sm"
                              as="select"
                              onChange={async (event) => {
                                handleOrderStatus(event, order.orderId);
                              }}
                            >
                              <option>{null}</option>
                              <option>inTransit</option>
                              <option>delivered</option>
                              <option>cancelled</option>
                            </Form.Control>
                          </Form>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ))}
              </tbody>
            </Table>
          </div>
        ) : null}
        {/* In Transit */}
        {selectedPill === "in-transit" ? (
          <div className="tabbed-table">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Order Placed On</th>
                  <th>Cust. Name</th>
                  <th>Cust. Phone</th>
                  <th>Cust. Email</th>
                  <th>Cust. Full Address</th>
                  <th>Order Placed From</th>
                  <th>Current Status</th>
                  <th>Order Details</th>
                  <th>Order Total</th>
                  <th>Payment Status</th>
                  <th>Change Status</th>
                </tr>
              </thead>
              <tbody>
                {allOrders.map((order, i) => (
                  <>
                    {order.orderStatus === "inTransit" ? (
                      <tr>
                        <td>{order.orderId}</td>
                        <th>
                          {`${order.orderTime.toDate().getDate()}
                                                        /${order.orderTime
                                                          .toDate()
                                                          .getMonth()}
                                                        /${order.orderTime
                                                          .toDate()
                                                          .getFullYear()}`}
                        </th>
                        <td>{order.customerDetails.fullName}</td>
                        <td>{order.customerDetails.customerPhone}</td>
                        <td>{order.customerDetails.customerEmail}</td>
                        <td width="150px">
                          {order.customerDetails.fullAddress}
                        </td>
                        <td>{order.orderPlacedFrom}</td>
                        <td>{order.orderStatus}</td>
                        <td>
                          <div className="table-inner-order-items">
                            <Table responsive bordered striped>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Quantity</th>
                                  <th>Store</th>
                                  <th>Price / Unit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {order.orderItems.map((item, i) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>
                                      {order.orderPlacedFrom !== "b2b form"
                                        ? item.quantity
                                        : item.count}
                                    </td>
                                    <td>{item.parentStore}</td>
                                    <td>{item.price} INR</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </td>
                        <td>{order.tAmount}</td>
                        <td>
                          <Form>
                            <Form.Control
                              size="sm"
                              as="select"
                              onChange={async (event) => {
                                handleOrderStatus(event, order.orderId);
                              }}
                            >
                              <option>{null}</option>
                              <option>delivered</option>
                              <option>cancelled</option>
                            </Form.Control>
                          </Form>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ))}
              </tbody>
            </Table>
          </div>
        ) : null}
        {/* Delivered */}
        {selectedPill === "delivered" ? (
          <div className="tabbed-table">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Order Placed On</th>
                  <th>Cust. Name</th>
                  <th>Cust. Phone</th>
                  <th>Cust. Email</th>
                  <th>Cust. Full Address</th>
                  <th>Order Placed From</th>
                  <th>Current Status</th>
                  <th>Order Details</th>
                  <th>Order Total</th>
                  <th>Payment Status</th>
                  <th>Change Status</th>
                </tr>
              </thead>
              <tbody>
                {allOrders.map((order, i) => (
                  <>
                    {order.orderStatus === "delivered" ? (
                      <tr>
                        <td>{order.orderId}</td>
                        <th>
                          {`${order.orderTime.toDate().getDate()}
                                                        /${order.orderTime
                                                          .toDate()
                                                          .getMonth()}
                                                        /${order.orderTime
                                                          .toDate()
                                                          .getFullYear()}`}
                        </th>
                        <td>{order.customerDetails.fullName}</td>
                        <td>{order.customerDetails.customerPhone}</td>
                        <td>{order.customerDetails.customerEmail}</td>
                        <td width="150px">
                          {order.customerDetails.fullAddress}
                        </td>
                        <td>{order.orderPlacedFrom}</td>
                        <td>{order.orderStatus}</td>
                        <td>
                          <div className="table-inner-order-items">
                            <Table responsive bordered striped>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Quantity</th>
                                  <th>Store</th>
                                  <th>Price / Unit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {order.orderItems.map((item, i) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>
                                      {order.orderPlacedFrom !== "b2b form"
                                        ? item.quantity
                                        : item.count}
                                    </td>
                                    <td>{item.parentStore}</td>
                                    <td>{item.price} INR</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </td>
                        <td>{order.tAmount}</td>
                        <td>
                          <Form>
                            <Form.Control
                              size="sm"
                              as="select"
                              onChange={async (event) => {
                                handleOrderStatus(event, order.orderId);
                              }}
                            >
                              <option>{null}</option>
                              <option>orderPlaced</option>
                              <option>inTransit</option>
                              <option>delivered</option>
                              <option>cancelled</option>
                            </Form.Control>
                          </Form>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ))}
              </tbody>
            </Table>
          </div>
        ) : null}
        {/* Cancelled */}
        {selectedPill === "cancelled" ? (
          <div className="tabbed-table">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Order Placed On</th>
                  <th>Cust. Name</th>
                  <th>Cust. Phone</th>
                  <th>Cust. Email</th>
                  <th>Cust. Full Address</th>
                  <th>Order Placed From</th>
                  <th>Current Status</th>
                  <th>Order Details</th>
                  <th>Order Total</th>
                  <th>Payment Status</th>
                  <th>Change Status</th>
                </tr>
              </thead>
              <tbody>
                {allOrders.map((order, i) => (
                  <>
                    {order.orderStatus === "cancelled" ? (
                      <tr>
                        <td>{order.orderId}</td>
                        <th>
                          {`${order.orderTime.toDate().getDate()}
                                                        /${order.orderTime
                                                          .toDate()
                                                          .getMonth()}
                                                        /${order.orderTime
                                                          .toDate()
                                                          .getFullYear()}`}
                        </th>
                        <td>{order.customerDetails.fullName}</td>
                        <td>{order.customerDetails.customerPhone}</td>
                        <td>{order.customerDetails.customerEmail}</td>
                        <td width="150px">
                          {order.customerDetails.fullAddress}
                        </td>
                        <td>{order.orderPlacedFrom}</td>
                        <td>{order.orderStatus}</td>
                        <td>
                          <div className="table-inner-order-items">
                            <Table responsive bordered striped>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Quantity</th>
                                  <th>Store</th>
                                  <th>Price / Unit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {order.orderItems.map((item, i) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>
                                      {order.orderPlacedFrom !== "b2b form"
                                        ? item.quantity
                                        : item.count}
                                    </td>
                                    <td>{item.parentStore}</td>
                                    <td>{item.price} INR</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </td>
                        <td>{order.tAmount}</td>
                        <td>
                          <Form>
                            <Form.Control
                              size="sm"
                              as="select"
                              onChange={async (event) => {
                                handleOrderStatus(event, order.orderId);
                              }}
                            >
                              <option>{null}</option>
                              <option>orderPlaced</option>
                              <option>inTransit</option>
                              <option>delivered</option>
                              <option>cancelled</option>
                            </Form.Control>
                          </Form>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ))}
              </tbody>
            </Table>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SuperAdminOrders;
