import React from 'react';
import './admin-edit-product-modal.styles.scss';
import { Modal, Form, Button } from 'react-bootstrap';
import { imgStorage, firestore } from '../../../../../firebase/firebase.utils';

const AdminEditProductModal = (props) => {

    const uploadInfo = {
        productId: props.productId,
        name: props.name,
        avail: props.avail,
        description: props.description,
        expiry: props.expiry,
        imageUrl: props.imageUrl,
        price: props.price,
        quantity: props.quantity,
        availableStock: props.availableStock,
        gst: props.gst,
        parentStore: props.parentStore,
        category: props.category,
        addedOn: props.addedOn,
        measure: props.measure
    };

    let imageName = null;
    let imageFile = null;

    const handleUpload = async () => {
        const folderRef = await imgStorage.ref().child('image-src-cat-store-main/coorg-basket-product-images');
        const fileRef = await folderRef.child(`${imageName}`);
        await fileRef.put(imageFile).then((snapshot) => alert("File Uploaded"));
        await fileRef.getDownloadURL().then((url) => uploadInfo.imageUrl = url);
    };

    const handleEditProduct = async () => {
        const docRefDelete = await firestore.collection("online-store").doc("products").collection("product-list");
        docRefDelete.get().then(async (snapshot) => {
            let docId = '';
            await snapshot.docs.map(doc => doc.data().productId === props.productId ? docId = doc.id : null);
            await firestore.collection("online-store").doc("products").collection("product-list").doc(`${docId}`)
                .update({
                    name: uploadInfo.name,
                    avail: Boolean(uploadInfo.avail),
                    description: uploadInfo.description,
                    expiry: uploadInfo.expiry,
                    imageUrl: uploadInfo.imageUrl,
                    price: Number(uploadInfo.price),
                    quantity: Number(uploadInfo.quantity),
                    availableStock: Number(uploadInfo.availableStock),
                    gst: Number(uploadInfo.gst),
                    parentStore: uploadInfo.parentStore,
                    category: uploadInfo.category,
                    measure: uploadInfo.measure
                })
                .then(async () => await alert('Document edited successfully'))
                .then(async () => await props.onHide)
                .then(async () => await window.location.reload(false))
        })
    }


    return (
        <Modal {...props} size="xl" className='admin-edit-products-modal-parent-container' aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header closeButton>
                <h6>Edit Product</h6>
            </Modal.Header>
            <Modal.Body>
                <Form className='admin-edit-products-modal-form'>
                    <div className='admin-edit-image-wrapper'>
                        <h6>Change Image</h6>
                        <img src={props.imageUrl} alt='edit-image' height='80px' />
                        <Form.File className='admin-edit-product-image-upload-field' id="exampleFormControlFile1" onChange={(event) => { imageName = event.target.files[0].name; imageFile = event.target.files[0] }} />
                        <Button className='admin-upload-image-button' variant={'info'} onClick={() => handleUpload()} >Upload Image</Button>
                    </div>

                    <div className='admin-edit-product-name-wrapper'>
                        <Form.Label className='admin-edit-product-label'>Product Name</Form.Label>
                        <Form.Control className='admin-edit-product-form-name' defaultValue={`${props.name}`} type="text" placeholder="Full Product Name" onChange={(event) => uploadInfo.name = event.target.value} />
                    </div>

                    <div className='admin-edit-product-price-quantity-wrapper'>
                        <div>
                            <Form.Label className='admin-edit-product-label'>MRP in ₹</Form.Label>
                            <Form.Control className='admin-edit-product-price' defaultValue={`${props.price}`} type="text" placeholder="MRP in ₹" onChange={(event) => uploadInfo.price = event.target.value} />
                        </div>
                        <div>
                            <Form.Label className='admin-edit-product-label'>Quantity</Form.Label>
                            <Form.Control className='admin-edit-product-quantity' defaultValue={`${props.quantity}`} type="text" placeholder="Quantity eg: 1 Lt / 200 ml / 1 KG" onChange={(event) => uploadInfo.quantity = event.target.value} />
                        </div>
                        <div>
                            <Form.Label>Select Measure</Form.Label>
                            <Form.Control as="select" onChange={(event) => uploadInfo.measure = event.target.value}>
                                <option selected>{props.measure}</option>
                                <option>kg</option>
                                <option>gm</option>
                                <option>lt</option>
                                <option>ml</option>
                                <option>packet/s</option>
                                <option>number/s</option>
                            </Form.Control>
                        </div>
                        <div>
                            <Form.Label className='admin-add-product-label'>Items In Stock</Form.Label>
                            <Form.Control className='admin-add-product-stock' defaultValue={`${props.availableStock}`} type="text" placeholder="10 / 20 / 30" onChange={(event) => uploadInfo.availableStock = Number(event.target.value)} />
                        </div>
                        <div>
                            <Form.Label className='admin-add-product-label'>Item GST %</Form.Label>
                            <Form.Control className='admin-add-product-gst' defaultValue={`${props.gst}`} type="text" placeholder="Item GST %" onChange={(event) => uploadInfo.gst = Number(event.target.value)} />
                        </div>
                    </div>

                    <div className='form-category-admin-wrapper'>
                        <div className='form-category-block'>
                            <Form.Label className='admin-edit-product-label'>Select Category</Form.Label>
                            <Form.Control className='admin-edit-product-category-select-field' as="select" onChange={(event) => uploadInfo.category = event.target.value} >
                                <option selected>{props.category}</option>
                                {
                                    props.categoryMeta.map((item, i) =>
                                        <option key={i}>{item.category}</option>
                                    )
                                }
                            </Form.Control>
                        </div>
                    </div>
                    <div className='admin-text-area-group'>
                        <div>
                            <Form.Label className='admin-edit-product-label'>Product Description</Form.Label>
                            <Form.Control className='admin-edit-product-description-area-field' defaultValue={`${props.description}`} as="textarea" rows="4" onChange={(event) => uploadInfo.description = event.target.value} />
                        </div>
                        <div>
                            <Form.Label className='admin-edit-product-label'>Expiry</Form.Label>
                            <Form.Control className='admin-edit-product-expiry-field' defaultValue={`${props.expiry}`} as="textarea" rows="4" onChange={(event) => uploadInfo.expiry = event.target.value} />
                        </div>
                    </div>
                </Form>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
                    <Button variant="primary" onClick={() => handleEditProduct()} >Save changes</Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
}

export default AdminEditProductModal;