import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./cat-store-single-product-page.styles.scss";
import { firestore } from "../../../firebase/firebase.utils";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// comp imports
import CatStoreHeader from "../../components/cat-store-front-end/cat-store-header/cat-store-header.component";
import CatStoreFooter from "../../components/cat-store-front-end/cat-store-footer/cat-store-footer.component";
import CatStoreProductDetails from "../../components/cat-store-front-end/cat-store-product-details/cat-store-product-details.component";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffff",
  },
}));

const CatStoreSingleProductPage = () => {
  const classes = useStyles();
  const [productDetails, setProductDetails] = useState([]);
  const [open, setOpen] = useState(false);
  let { productId } = useParams();

  // Func to set backdrops
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  // effect to fetch product data
  useEffect(() => {
    const fetchShopData = async () => {
      await setOpen(true);
      const docRef = await firestore
        .collection("online-store")
        .doc("products")
        .collection("product-list")
        .where("productId", "==", `${productId}`);
      docRef
        .get()
        .then((snapshot) => {
          let data = [];
          snapshot.docs.map((doc) => data.push(doc.data()));
          setProductDetails(data[0]);
        })
        .then(() => setTimeout(() => setOpen(false), 500));
    };
    fetchShopData();
  }, []);

  return (
    <div>
      <CatStoreHeader />
      <CatStoreProductDetails productDetails={productDetails} />
      <CatStoreFooter />
      {/* Backdrop */}
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default CatStoreSingleProductPage;
