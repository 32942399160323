import React from 'react';
import './admin-footer.styles.scss';


const AdminFooter = () => {
    return (
        <div className='admin-footer-main-container'>
            <div className='admin-footer-text-wrapper'>
                © Admin Panel Powered By CatStore
            </div>
        </div>
    )
}

export default AdminFooter;