import React, { useState } from 'react';
import './cat-store-signup-page.styles.scss';
import { Link, useHistory } from "react-router-dom";
import { signInWithGoogle, auth, generateUserDocument } from '../../../firebase/firebase.utils';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Snackbar from '@material-ui/core/Snackbar';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLogo from '../../../assets/cat-store-main-logo.png';


const CatStoreSignUpPage = () => {
    let history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [error, setError] = useState(null);

    // Handle Error Snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // Create Sign Up
    const createUserWithEmailAndPasswordHandler = async (event, email, password) => {
        event.preventDefault();
        try {
            const { user } = await auth.createUserWithEmailAndPassword(email, password);
            await generateUserDocument(user, { displayName });
            await history.push("/")
        }
        catch (error) {
            setError(`Error Signing up with email and password - ${error}`);
            setOpenSnackbar(true);
        }

        setEmail("");
        setPassword("");
        setDisplayName("");
    };

    return (
        <div className="signup-page-main-container">
            <div className="signup-page-form-container">
                <img src={MainLogo} alt="main-logo" />
                <form className="login-form" noValidate autoComplete="off">
                    <TextField type="text" id="userName" placeholder="Your Full Name" variant="outlined" color="primary" value={displayName} onChange={e => setDisplayName(e.target.value)} /><br />
                    <TextField type="email" id="userEmail" placeholder="Your Email" variant="outlined" color="primary" value={email} onChange={e => setEmail(e.target.value)} /><br />
                    <TextField type="password" id="userPassword" placeholder="Password" variant="outlined" color="primary" value={password} onChange={e => setPassword(e.target.value)} /><br />
                    <Button style={{ backgroundColor: "green", color: "white" }} variant="contained" startIcon={<LockOpenIcon />} onClick={event => { createUserWithEmailAndPasswordHandler(event, email, password); }}> Sign up</Button><br />
                    {/* <span style={{ textAlign: "center", textShadow: "1px 1px 1px grey" }}><b>OR</b></span><br /> */}
                    <Button style={{ backgroundColor: "#1a73e8", color: "white" }} variant="contained" onClick={signInWithGoogle}><FontAwesomeIcon style={{ marginRight: "10px" }} icon={faGoogle} /> Sign In with Google</Button>
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} message={`${error}`} />
                </form>
            </div>
            <div className="signup-page-bottom-links">
                <Link to="/login" className="react-link"><b>Already Have an Account ? &#10074;&#10074;&#10074; <span style={{ color: "red", marginLeft: "5px" }}> Click Here To Sign In</span></b></Link>
            </div>
        </div>
    )
}

export default CatStoreSignUpPage;