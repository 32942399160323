import React from "react";
import Timer from "./timer";

import "./styles.css";

function ComingSoon() {
  return (
    <div className="App">
      <div className="container text-white">
        <h1>
          Site under reconstruction
          <br /> <br />
          To be launched soon
        </h1>
        <br />

        <Timer />
      </div>
    </div>
  );
}

export default ComingSoon;
