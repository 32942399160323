import React, { useState } from "react";
import "./cat-store-login-page.styles.scss";
import { Link, useHistory } from "react-router-dom";
import { signInWithGoogle, auth } from "../../../firebase/firebase.utils";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Snackbar from "@material-ui/core/Snackbar";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  faUserCheck,
  faPassport,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Navbar } from "react-bootstrap";
import MainLogo from "../../../assets/cat-store-main-logo.png";

const CatStoreLoginPage = () => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  // Handle Error Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Sign In Handler
  const signInWithEmailAndPasswordHandler = async (event, email, password) => {
    await event.preventDefault();
    await auth
      .signInWithEmailAndPassword(email, password)
      .then(() => history.push("/"))
      .catch(async (error) => {
        await setError(`Error Signing in with email and password - ${error}`);
        await setOpenSnackbar(true);
        // await console.error("Error signing in with password and email", error);
      });
  };

  return (
    <div className="login-page-main-container">
      <div className="login-page-form-container">
        <img src={MainLogo} alt="main-logo" />
        <form className="login-form" noValidate autoComplete="off">
          <TextField
            type="email"
            className="field"
            placeholder="Email Address"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <TextField
            type="password"
            className="field"
            placeholder="Password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <Button
            className="material-button"
            color="primary"
            variant="contained"
            onClick={(event) => {
              signInWithEmailAndPasswordHandler(event, email, password);
            }}
            startIcon={<LockOpenIcon />}
          >
            Sign In
          </Button>
          <br />
          {/* <span style={{ textAlign: "center", textShadow: "1px 1px 1px grey" }}><b>OR</b></span><br /> */}
          <Button
            className="material-button"
            style={{ backgroundColor: "#1a73e8", color: "white" }}
            variant="contained"
            onClick={async () => {
              await signInWithGoogle();
              await history.push("/");
            }}
          >
            <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faGoogle} />{" "}
            Sign in with Google{" "}
          </Button>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          message={`${error}`}
        />
      </div>
      <div className="login-page-bottom-links">
        <Link className="react-link" to="/register">
          <FontAwesomeIcon style={{ color: "green" }} icon={faUserCheck} />{" "}
          <b>Click Here To Sign Up</b>
        </Link>
        <Link className="react-link" to="/forgot-password">
          <FontAwesomeIcon style={{ color: "green" }} icon={faPassport} />{" "}
          <b>Forgot Password ?</b>
        </Link>
      </div>
      <span
        className="without-login"
        onClick={async () => {
          history.push("/home");
        }}
      >
        Continue Without Logging In{" "}
        <FontAwesomeIcon
          style={{ marginLeft: "10px" }}
          icon={faArrowCircleRight}
        />
      </span>
    </div>
  );
};

export default CatStoreLoginPage;
