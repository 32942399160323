import React, { useContext, useState } from "react";
import firebase from "firebase/app";
import "./admin-categories.styles.scss";
import AdminProductCard from "../admin-product-card/admin-product-card.component";
import { CatStoreAdminNavCategoryContext } from "../../../contexts/admin-nav-category-item/admin-nav-category-item.context";
import { Button, Table } from "react-bootstrap";

import { firestore } from "../../../../firebase/firebase.utils";

const AdminCategories = ({ shopData, categoryMeta }) => {
  const [navCategory, setNavCategory] = useContext(
    CatStoreAdminNavCategoryContext
  );
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [categoryExists, setCategoryExists] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [showAddSubCategory, setShowAddSubCategory] = useState(false);

  const filteredProducts = shopData.filter(
    (item) =>
      (item.category
        ? item.category.toLowerCase().includes(navCategory.toLowerCase())
        : null) ||
      (item.subCategory
        ? item.subCategory.toLowerCase().includes(navCategory.toLowerCase())
        : null)
  );

  // Handle Category Add
  const handleSubmitCategory = async () => {
    const catRef = await firestore
      .collection("online-store")
      .doc("categories")
      .collection("category-meta-data");
    // await catRef.get().then(async (snapshot) => {
    //   await snapshot.docs.map((doc) =>
    //     doc.data().category.toLowerCase() === categoryName.toLowerCase()
    //       ? alert("You tried adding a category which is already available.")
    //       : setCategoryExists(false)
    //   );
    catRef
      .add({ category: categoryName.toUpperCase() })
      .then(async () => await alert("New Category Has Been Added"))
      .then(async () => await window.location.reload(false));

    // });
  };

  // Handle New Category Add selectedCategory subCategoryName
  const handleSubmitSubCategory = async () => {
    const catRef = await firestore
      .collection("online-store")
      .doc("categories")
      .collection("category-meta-data");
    catRef.get().then(async (snapshot) => {
      let docId = "";
      await snapshot.docs.map((doc) =>
        doc.data().category.toLowerCase() === selectedCategory.toLowerCase()
          ? (docId = doc.id)
          : null
      );
      // await console.log(docId)
      const catDocRef = await firestore
        .collection("online-store")
        .doc("categories")
        .collection("category-meta-data")
        .doc(`${docId}`);
      await catDocRef
        .set(
          {
            subCategory: firebase.firestore.FieldValue.arrayUnion(
              subCategoryName.toLowerCase()
            ),
          },
          { merge: true }
        )
        .then(async () => await alert("Subcategory Added Successfully"))
        .then(async () => await window.location.reload(false));
    });
  };

  return (
    <div className="admin-categories-main-container">
      <h1>CATEGORIES</h1>
      <div className="admin-categories-action-buttons">
        <div>
          <Button
            className="admin-add-category-button"
            variant={"success"}
            onClick={() => setShowAddCategory(!showAddCategory)}
          >
            Add Category
          </Button>
          {/* <Button className='admin-add-category-button' variant={'info'} onClick={() => setShowAddSubCategory(!showAddSubCategory)} >Add Sub Category</Button> */}
        </div>
        <br />
        {showAddCategory ? (
          <div className="admin-add-category-dropdown">
            <input
              type="text"
              className="admin-add-category-input"
              placeholder="Category Name"
              onChange={(event) => setCategoryName(event.target.value)}
            />
            <button
              className="admin-add-category-button"
              onClick={() => handleSubmitCategory()}
            >
              Add Category
            </button>
          </div>
        ) : null}
        <br />
        {showAddSubCategory ? (
          <div className="admin-add-sub-category-dropdown">
            <select
              name="select-category"
              className="admin-add-sub-category-select"
              onChange={(event) => setSelectedCategory(event.target.value)}
              required
            >
              <option selected>Select A Category *</option>
              {categoryMeta.map((catItem) => (
                <option>{catItem.category}</option>
              ))}
            </select>
            <input
              type="text"
              className="admin-add-sub-category-input"
              placeholder="Sub-Category Name"
              onChange={(event) => setSubCategoryName(event.target.value)}
            />
            <button
              className="admin-add-sub-category-button"
              onClick={() => handleSubmitSubCategory()}
            >
              Add Sub-Category
            </button>
          </div>
        ) : null}
      </div>
      <div className="admin-all-products-list">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Category Name</th>
            </tr>
          </thead>
          <tbody>
            {categoryMeta.map((item, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{item.category}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AdminCategories;
