import React from 'react';
import CatStoreHeader from '../../cat-store/components/cat-store-front-end/cat-store-header/cat-store-header.component';
import CatStoreFooter from '../../cat-store/components/cat-store-front-end/cat-store-footer/cat-store-footer.component';
import './privacy-policy-page.styles.scss';

const PrivacyPolicy = () => {
    // effect to scroll to top
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <CatStoreHeader />
            <div className='privacy-policy-main-container'>
                <h2>Privacy Policy</h2>
                <p>Kodagu Trade and Commerce Pvt. Ltd through its store at catkodagu.com or through stores deployed in various websites collect certain information
                of visitors to the stores. We, meaning Kodagu Trade and Commerce Pvt. Ltd, also collect data on all websites that have deployed our store at their
                website as well as the data of their administrators. This note on our Privacy Policy describes our practices in connection with all data thus collected.
                By using our services completely or partially or on casual visit to understand our offerings or visiting our stores for any other reason not described,
                you are accepting and consenting to our practices described in this Privacy Policy. You agree that we or various websites that have deployed our store
                in their website may collect, process and use all information collected for purposes mentioned in paragraphs below and in ways described below. For all
                communication, you shall mention the number of the paragraph shown before the paragraph. This paragraph will be called introductory paragraph and not
                numbered but binding. </p>
                <ol>
                    <li>All information provided or gathered will be stored by Kodagu Trade and Commerce Pvt. Ltd or by the owners of websites where our store has been deployed. </li>
                    <li>All information will be used to register you, verify your identity to permit you to use our services, undertake transactions, communicate with you and inform you of promotional offers, services, updates etc. This may be used to maintain your accounts and deliver better customer experience.</li>
                    <li>We may deploy cookies and obtain certain types of information when your web browser interacts with our system. This may include information like your location and your device with its unique identifier. We may use this information for analysis and to provide you with location based services including personalized content. You have the right to enable your browser to prevent the acceptance of such cookies. </li>
                    <li>We may receive information about you from third party sources and use it to communicate with your in line with our normal practices. You are entitled to stop us from communicating with you when your information has been gathered through third party sources. However, if you use our services or visit any of our stores at any time, information gathered during this process will be used as enunciated in this Privacy Policy and you shall not stop us from using your data to improve our services or communicate with you.</li>
                    <li>We may be required to share all information or any information with government authorities or government authorized institutions or agencies, our auditors, secretaries and attorneys for various purposes including identity verification, prevention, detection or investigation of crimes, incidents or prosecution and punishment for offences. You agree and consent for disclosure for all or any of your information if required under applicable law.</li>
                    <li>All or any information about our users is very important to us and we use it to better our services to them and shall not under any circumstance sell it to others. We may share user information with other website owners who have deployed our store in their website or with a family of businesses owned or controlled by us that follow Privacy Policy similar to us.</li>
                    <li>We do not disclose your financial information such as your credit/debit card information to anyone without your prior consent.</li>
                    <li>When you make a purchase we may make some personal information of you such as name, mobile number, email, shipping address etc available to any third party to complete your transaction including delivery. The use of such information that we provide will be governed by the Agreements or Terms and Conditions or Privacy Policy of that recipient.</li>
                    <li>We may use the services of third party service providers such as financial institutions, or companies and individuals to perform functions on our behalf and transactions that you request or authorize or needed to complete the transaction, providing assistance, processing payments and transactions, verifying identity, fraud screening, bettering customer service etc. We will share your personal information with them to enable them to perform the required functions and will be governed by their Agreements or Terms and Conditions or Privacy Policy.</li>
                    <li>We may sell or buy businesses, subsidiaries, business units; or our assets or business may be substantially or completely acquired. In all such events, user information will be one of the transferred assets.</li>
                    <li>Our Site may have third-party advertising and/or links to other websites. We will not provide any personally identifiable user information to these advertisers or third-party websites. These third-party websites and advertisers, or Internet advertising companies working on their behalf, may use technology to send (or "serve") the advertisements that appear on the store directly through your browser. They automatically receive your IP address when this happens. They may also use cookies, JavaScript, web beacons (also known as action tags or single-pixel gifs), and other technologies to measure the effectiveness of their advertisements and to personalize the advertising content. We do not have access to or control over cookies or other features that they may use, and the information practices of these advertisers and third-party websites are not covered by this Privacy Policy. Please contact them directly for more information about their privacy practices.</li>
                    <li>You can access all of your information we have about you and you may make the necessary changes you deem necessary regarding your personal details. You will not be able to make any changes on the information concerning your purchasing pattern, or details of your transactions. </li>
                    <li>By using our services you agree that you can form a legally binding contract under the Indian Contract Act, 1872.</li>
                    <li>If you have any dispute on privacy issues, you agree that your dispute or points of disagreement will be subject to this Privacy Policy only, which you agree to by using our services or visiting our store at catkodagu.com or any other website where the store is deployed. </li>
                    <li>Businesses and business practices constantly evolve and change and with this the Privacy Policy will also change. You may or may not receive any notice on the change of Privacy Policy. You agree to abide by the most recent Privacy Policy which would be in force on the date of your interaction with us. This version of the Privacy Policy is as on November 4, 2020 and applies to all information we have about you and your account. All your information will be stored and retained by us until this business exists or as explained in paragraph 10 above or if any law is passed that will determine the privacy policy of businesses and organizations. </li>
                </ol>
            </div>
            <CatStoreFooter />
        </>
    )
}

export default PrivacyPolicy;