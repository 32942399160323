import React, { useState, useEffect } from "react";
import "./admin-dashboard.styles.scss";
import { Table, Modal, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { firestore } from "../../../../firebase/firebase.utils";

const AdminDashboard = ({ shopData, categoryMeta }) => {
  const [storeDetails, setStoreDetails] = useState([]);
  const [storeDetailsFound, setStoreDetailsFound] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = async () => {
    await setShow(true);
  };

  // Get Store Info
  useEffect(() => {
    const fetchStoreDetails = async () => {
      const docRef = await firestore.collection("store-details");
      docRef.get().then(async (snapshot) => {
        let details = [];
        await snapshot.docs.map((doc) => details.push(doc.data()));
        await setStoreDetails(details);
      });
    };
    fetchStoreDetails();
  }, [setStoreDetails]);

  return (
    <div className="admin-dashboard-main-container">
      {/* View Store Details Modal */}
      {storeDetailsFound ? (
        <Modal
          show={show}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <h3>Store Details</h3>
          </Modal.Header>
          <Modal.Body>
            <div className="admin-dashboard-view-store-modal-container">
              <span>
                <b>Store ID:</b> {storeDetailsFound.storeId}
              </span>
              <br />
              <span>
                <b>Store Name:</b> {storeDetailsFound.storeName}
              </span>
              <br />
              <span>
                <b>Store Contact:</b> {storeDetailsFound.storeContact}
              </span>
              <br />
              <span>
                <b>Store Email:</b> {storeDetailsFound.storeEmail}
              </span>
              <br />
              <span>
                <b>Store GSTIN:</b> {storeDetailsFound.storeGstin}
              </span>
              <br />
              <span>
                <b>Store Adress:</b> {storeDetailsFound.storeAddress}
              </span>
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => handleClose()}>
              {" "}
              Done{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      <h2>
        <FontAwesomeIcon
          className="fa-icon-admin-categories"
          icon={faChartBar}
        />{" "}
        STORE DASHBOARD
      </h2>
      <div className="admin-brief-box-container">
        <div className="admin-brief-box">
          <h5>Total Products</h5>
          <span className="box-count">{shopData.length}</span>
        </div>
        <div className="admin-brief-box">
          <h5>Total Categories</h5>
          <span className="box-count">{categoryMeta?.length}</span>
        </div>
      </div>
      {/* Table */}
      <div className="admin-all-products-list">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Store</th>
              <th>Category</th>
              {/* <th>Sub-Category</th> */}
              <th>Price</th>
              <th>Quantity</th>
              <th>In-Stock</th>
            </tr>
          </thead>
          <tbody>
            {shopData.map((item, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{item.name}</td>
                <td>{item.parentStore}</td>
                <td>{item.category}</td>
                {/* <td>{item.subCategory ? item.subCategory : 'None'}</td> */}
                <td>{item.price}</td>
                <td>{item.quantity}</td>
                <td>{item.availableStock}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AdminDashboard;
