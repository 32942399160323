import React, { useState } from "react";
import "./cat-store-forgot-password.styles.scss"
import { Link } from "react-router-dom";
import { auth } from '../../../firebase/firebase.utils';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { faMailBulk, faBackward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const CatStorePasswordReset = () => {
    const [email, setEmail] = useState("");
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
    const [error, setError] = useState(null);

    // Handle Error Snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const sendResetEmail = event => {
        event.preventDefault();
        auth
            .sendPasswordResetEmail(email)
            .then(() => {
                setEmailHasBeenSent(true);
                setError(`Yaaay! Reset Email Has Been Sent!!`);
                setOpenSnackbar(true);
                setTimeout(() => { setEmailHasBeenSent(false) }, 3000);
            })
            .catch((error) => {
                setError(`Error resetting password : ${error}`);
                setOpenSnackbar(true);
            });
        setEmail("");
    };

    return (
        <div className="password-reset-main">
            <h1>RESET PASSWORD</h1>
            <div className="form-enclosing">
                <form className='reset-form'>
                    <TextField type="email" id="userEmail" placeholder="Your Registered Email" variant="outlined" color="primary" value={email} onChange={(e) => setEmail(e.target.value)} /><br />
                    <Button style={{ backgroundColor: "green", color: "white" }} variant="contained" onClick={(event) => sendResetEmail(event)}><FontAwesomeIcon style={{ marginRight: "10px" }} icon={faMailBulk} />Send me a reset link</Button>
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} message={`${error}`} />
                </form>
            </div>
            <Link className="react-link" to="/login"><b><FontAwesomeIcon style={{ marginRight: "5px" }} icon={faBackward} /> Go Back</b></Link>
        </div>
    );
};
export default CatStorePasswordReset;