import React, { useContext } from "react";
import { Route } from "react-router-dom";

// Cat Store Imports
import CatStoreMainRoutes from "../cat-store/pages/cat-store-main-page/cat-store-main-page.component";
import CatStoreSignUpPage from "../cat-store/pages/cat-store-signup-page/cat-store-signup-page.component";
import CatStoreLoginPage from "../cat-store/pages/cat-store-login-page/cat-store-login-page.component";
import CatStoreSingleProductPage from "../cat-store/pages/cat-store-single-product-page/cat-store-single-product-page.component";
import CatStoreCategoryViewPage from "../cat-store/pages/cat-store-category-view-page/cat-store-category-view-page.component";
import CatStorePasswordReset from "../cat-store/pages/cat-store-forgot-password/cat-store-forgot-password.component";
import CatStoreCheckoutPage from "../cat-store/pages/cat-store-checkout-page/cat-store-checkout-page.component";
import TermasAndConditions from "../other-pages/terms-and-conditions-page/terms-and-conditions-page.component";
import PrivacyPolicy from "../other-pages/privacy-policy-page/privacy-policy-page.component";
import CatStoreMyAccountPage from "../cat-store/pages/cat-store-my-account-page/cat-store-my-account-page.component";
import CatStoreStoreViewPage from "../cat-store/pages/cat-store-store-view-page/cat-store-store-view-page.component";
import CatStorePaymentStatusPage from "../cat-store/pages/cat-store-payment-status-page/cat-store-payment-status-page.component";

// Store admin pages
import CatStoreAdminPageHasmukh from "../store-admins/hasmukh-foods/pages/cat-store-admin-page/cat-store-admin-page.component";
import CatStoreAdminPageOmProducts from "../store-admins/om-products/pages/cat-store-admin-page/cat-store-admin-page.component";

// Admin page import
import CatStoreAdminPage from "../cat-store/pages/cat-store-admin-page/cat-store-admin-page.component";
import CatStoreSuperAdminPage from "../cat-store/pages/cat-store-super-admin-page/cat-store-super-admin-page.component";

// Context imports
import { UserContext } from "../cat-store/contexts/user-provider/UserProvider";
import { CatStoreAdminNavCategoryProvider } from "../cat-store/contexts/admin-nav-category-item/admin-nav-category-item.context";
import { CatStoreSuperAdminNavCategoryProvider } from "../cat-store/contexts/super-admin-nav-category-item/super-admin-nav-category-item.context";
import { HasmukStoreAdminNavCategoryProvider } from "../store-admins/hasmukh-foods/contexts/admin-nav-category-item/admin-nav-category-item.context";
import { OmStoreAdminNavCategoryProvider } from "../store-admins/om-products/contexts/admin-nav-category-item/admin-nav-category-item.context";

//Coming soon
import ComingSoon from "../utils/comingSoon";

const Application = () => {
  const user = useContext(UserContext);

  return (
    <div>
      {/* <Route exact path="/" component={ComingSoon} /> */}
      {user !== undefined ? (
        <>
          <Route exact path="/home" component={CatStoreMainRoutes} />
          <Route
            path="/product/:productTitle/:productId"
            component={CatStoreSingleProductPage}
          />
          <Route
            path="/category/:categoryName"
            component={CatStoreCategoryViewPage}
          />
          <Route path="/store/:storeName" component={CatStoreStoreViewPage} />
          <Route path="/checkout" component={CatStoreCheckoutPage} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermasAndConditions}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/my-account" component={CatStoreMyAccountPage} />
          <Route
            exact
            path="/payment-status"
            component={CatStorePaymentStatusPage}
          />
          <Route exact path="/" component={CatStoreMainRoutes} />
        </>
      ) : (
        <>
          <Route exact path="/home" component={CatStoreMainRoutes} />
          <Route
            path="/product/:productTitle/:productId"
            component={CatStoreSingleProductPage}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermasAndConditions}
          />
          <Route exact path="/" component={CatStoreLoginPage} />
        </>
      )}
      {/* All other Routes go here -Privacy policy and all */}
      <Route exact path="/register" component={CatStoreSignUpPage} />
      <Route exact path="/login" component={CatStoreLoginPage} />
      <Route exact path="/forgot-password" component={CatStorePasswordReset} />
      {/* /* Admin page */}
      <CatStoreAdminNavCategoryProvider>
        <Route exact path="/admin2022" component={CatStoreAdminPage} />
      </CatStoreAdminNavCategoryProvider>
      <CatStoreSuperAdminNavCategoryProvider>
        <Route
          exact
          path="/super-admin2022"
          component={CatStoreSuperAdminPage}
        />
      </CatStoreSuperAdminNavCategoryProvider>
      {/* <HasmukStoreAdminNavCategoryProvider>
        <Route path="/hasmukfood/admin" component={CatStoreAdminPageHasmukh} />
      </HasmukStoreAdminNavCategoryProvider>
      <OmStoreAdminNavCategoryProvider>
        <Route
          path="/omproducts/admin"
          component={CatStoreAdminPageOmProducts}
        />
      </OmStoreAdminNavCategoryProvider> */}
    </div>
  );
};

export default Application;
