import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyD2tXAVWy-rmIXxzgqWUzOEQwFFdqgbLTw",
    authDomain: "cat-store-test.firebaseapp.com",
    databaseURL: "https://cat-store-test.firebaseio.com",
    projectId: "cat-store-test",
    storageBucket: "cat-store-test.appspot.com",
    messagingSenderId: "155935973609",
    appId: "1:155935973609:web:777d7afa4dd4077310d078"
};



firebase.initializeApp(config);

export const firestore = firebase.firestore();
export const imgStorage = firebase.storage();
export const auth = firebase.auth();

// Sign In with Google
const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
    auth.signInWithPopup(provider);
};

// Email Sign In
export const generateUserDocument = async (user, additionalData) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
        const { email, displayName, photoURL } = user;
        try {
            await userRef.set({
                displayName,
                email,
                photoURL,
                ...additionalData
            });
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }
    return getUserDocument(user.uid);
};


const getUserDocument = async uid => {
    if (!uid) return null;
    try {
        const userDocument = await firestore.doc(`users/${uid}`).get();
        return {
            uid,
            ...userDocument.data()
        };
    } catch (error) {
        console.error("Error fetching user", error);
    }
};