import React, { useState } from 'react';
import './admin-login.styles.scss';
import { Button, Form, Modal } from 'react-bootstrap';
import catStoreLogo from '../../../../assets/cat-store-main-logo.png';
import { firestore } from '../../../../firebase/firebase.utils';


const SuperAdminLogin = ({ setIsLoggedIn, setWhichAdmin }) => {
    const [emailId, setEmailId] = useState('');
    const [password1, setPassword1] = useState('');


    // HANDLE LOGIN
    const handleLogin = async () => {
        const docRefUser = await firestore.collection("stores").doc("cat-store-user-profile");
        await docRefUser.get()
            .then((doc) => {
                if (emailId === doc.data().superAdmin && password1 === doc.data().superPassword) {
                    localStorage.setItem('loggedIn', true);
                    localStorage.setItem('store', 'super-admin');
                    setWhichAdmin('super-admin');
                    return setIsLoggedIn(true);
                } else {
                    alert("Wrong Credentials")
                }
            })
    }

    

    return (
        <div className='admin-login-main-container'>
            <img src={catStoreLogo} width='100px' alt='cat-admin-logo' />
            <Form className='admin-login-form'>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="email" placeholder="Enter Username" onChange={(event) => setEmailId(event.target.value)} />
                    {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(event) => setPassword1(event.target.value)} />
                </Form.Group>
                <Button variant={'success'} onClick={() => handleLogin()}>Login</Button><br />
            </Form>
            <span className='copyright-stamp-admin'>Try Logging in to the Super admin panel <b>ONLY</b> if you are <b>AUTHORIZED</b></span>
            <span className='copyright-stamp-admin'>© Super Admin Panel Powered By CatStore</span>

           
        </div>
    )
}

export default SuperAdminLogin;