import React, { useState, useContext, useEffect } from "react";
import "./admin-left-navigation.styles.scss";
import { CatStoreAdminNavCategoryContext } from "../../../contexts/admin-nav-category-item/admin-nav-category-item.context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faBars,
  faSitemap,
  faQuestion,
  faPortrait,
  faShoppingCart,
  faFileInvoice,
  faFilePowerpoint,
  faBarcode,
} from "@fortawesome/free-solid-svg-icons";
import { firestore } from "../../../../firebase/firebase.utils";

const AdminLeftNavigation = ({
  shopData,
  currentComponent,
  setCurrentComponent,
  categoryMeta,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [navCategory, setNavCategory] = useContext(
    CatStoreAdminNavCategoryContext
  );

  // effect to check for dropdown
  useEffect(() => {
    const getLocalStorage = () => {
      if (localStorage.getItem("currentComponentLocal") === "categories") {
        return setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
    };
    getLocalStorage();
  }, []);

  return (
    <div className="navigation-main-container">
      <div className="current-store-logo">
        {/* <img src='https://coorgbasket.com/storage/2020/07/logo-coorgBasket.png' width='70%' alt='current-store-logo' /> */}
        <h6>ADMIN PANEL</h6>
      </div>
      <nav className="admin-main-navigation">
        <span
          className={
            currentComponent === "dashboard" ? `nav-item-active` : `nav-item`
          }
          onClick={() => {
            setShowDropdown(false);
            setCurrentComponent("dashboard");
            localStorage.setItem("currentComponentLocal", "dashboard");
          }}
        >
          <FontAwesomeIcon
            className="fa-icon-admin-categories"
            icon={faChartBar}
          />
          Dashboard
        </span>
        <span
          className={
            currentComponent === "categories" ? `nav-item-active` : `nav-item`
          }
          onClick={() => {
            setShowDropdown(!showDropdown);
            setCurrentComponent("categories");
            localStorage.setItem("currentComponentLocal", "categories");
          }}
        >
          <FontAwesomeIcon className="fa-icon-admin-categories" icon={faBars} />
          Categories
        </span>

        {/* {
                    showDropdown ?
                        categoryMeta.map((catItem, i) =>

                            <>
                                <a key={i} className='nav-inner-item' href={`#admin-${catItem.category}`} onClick={() => setNavCategory(catItem.category)} >
                                    - {catItem.category}
                                </a>

                                {
                                    catItem.subCategory ?
                                        catItem.subCategory.map((subItem, i) =>
                                            < a key={i} className='nav-second-level-item' href={`#admin-${catItem.category}-${subItem}`}
                                                onClick={() => setNavCategory(subItem)} >- {subItem}</a>
                                        )
                                        : null
                                }
                            </>
                        )
                        : null
                } */}
        <span
          className={
            currentComponent === "products" ? `nav-item-active` : `nav-item`
          }
          onClick={() => {
            setShowDropdown(false);
            setCurrentComponent("products");
            localStorage.setItem("currentComponentLocal", "products");
          }}
        >
          <FontAwesomeIcon
            className="fa-icon-admin-categories"
            icon={faSitemap}
          />
          Products
        </span>
        <span
          className={
            currentComponent === "orders" ? `nav-item-active` : `nav-item`
          }
          onClick={() => {
            setShowDropdown(false);
            setCurrentComponent("orders");
            localStorage.setItem("currentComponentLocal", "orders");
          }}
        >
          <FontAwesomeIcon
            className="fa-icon-admin-categories"
            icon={faShoppingCart}
          />
          Orders
        </span>
        <span
          className={
            currentComponent === "profile" ? `nav-item-active` : `nav-item`
          }
          onClick={() => {
            setShowDropdown(false);
            setCurrentComponent("profile");
            localStorage.setItem("currentComponentLocal", "profile");
          }}
        >
          <FontAwesomeIcon
            className="fa-icon-admin-categories"
            icon={faPortrait}
          />
          Profile
        </span>
      </nav>
    </div>
  );
};

export default AdminLeftNavigation;

// {
//     item.subCategory ?
//         <a key={i} className='nav-second-level-item' href={`#admin-${item.category}-${item.subCategory}`}
//             onClick={() => setNavCategory(item.subCategory)} >- {item.subCategory}</a>
//         : null
// }
