import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../contexts/user-provider/UserProvider';
import { firestore } from '../../../firebase/firebase.utils';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

// All component imports go here
import CatStoreHeader from '../../components/cat-store-front-end/cat-store-header/cat-store-header.component';
import CatStorePaymentStatus from '../../components/cat-store-front-end/cat-store-payment-status/cat-store-payment-status.component';
import CatStoreFooter from '../../components/cat-store-front-end/cat-store-footer/cat-store-footer.component';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#ffff',
    },
}));

const CatStorePaymentStatusPage = () => {
    const classes = useStyles();
    let user = useContext(UserContext);
    const [open, setOpen] = useState(false);

    // Func to set backdrops
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };


    return (
        <div>
            <CatStoreHeader user={user} />
            <CatStorePaymentStatus user={user} />
            <CatStoreFooter />
            {/* Backdrop */}
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default CatStorePaymentStatusPage;
