import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    // do action when finished install
    window.addEventListener("appinstalled", (e) => {
      alert("app installed, please check in your app drawer!");
      setSupportsPWA(true);
    });

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  // if are standalone android OR safari
  // if (
  //   window.matchMedia("(display-mode: standalone)").matches ||
  //   window.navigator.standalone === true
  // ) {
  //   return null;
  // }

  if (!supportsPWA) {
    return null;
  }

  return (
    <div className="mr-3">
      <Button variant="primary" onClick={onClick}>
        Install App
      </Button>
    </div>
  );
};

export default InstallPWA;
