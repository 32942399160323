import sjcl from 'sjcl';
import CryptoJS from 'crypto-js';


// Function to hash using sjcl
const hashFunction = (hashString) => {
    const myBitArray = sjcl.hash.sha256.hash(hashString);
    const myHash = sjcl.codec.hex.fromBits(myBitArray);
    return myHash
}


// Hash => Encrypt by taking in the main parameters => returns a base64 string
export const hashAndEncryptAes = (cid, rid, crn, amt) => {
    var CID = `${cid}`;
    var RID = `${rid}`;
    var CRN = `${crn}`;
    var AMT = `${amt}`;
    var VER = "1.0";
    var TYP = "TEST";
    var CNY = "INR";
    var RTU = "https://catkodagu.com/payment-status"
    var hashKey = "axis";
    var hashString = `${CID}${RID}${CRN}${AMT}${hashKey}`;
    var hash = hashFunction(hashString);
    var encStr = `CID=${CID}&RID=${RID}&CRN=${CRN}&AMT=${AMT}&VER=${VER}&TYP=${TYP}&CNY=${CNY}&RTU=${RTU}&PPI=1|1|1|1|1|1&RE1=MN&RE2=&RE3=&RE4=&RE5=&CKS=${hash}`
    var key = "axisbank12345678";
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key)
    var result = CryptoJS.AES.encrypt(encStr, keyutf, {
        iv,
        keySize: 128,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    var b64 = result.ciphertext.toString(CryptoJS.enc.Base64);
    return b64;
}

function hex2a(hexx) {
    var hex = hexx.toString();//force conversion
    var str = '';
    for (var i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
}

export const decrypt = (recievedEncryptedString) => {
    var key = "axisbank12345678";
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key)
    var decrypted3 = CryptoJS.AES.decrypt(recievedEncryptedString, keyutf, { iv, keySize: 128, mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    var string = hex2a(CryptoJS.enc.Hex.stringify(decrypted3));
    return string;

}