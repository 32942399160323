import React, { useState, createContext } from 'react';


export const CatStoreSuperAdminNavCategoryContext = createContext();

export const CatStoreSuperAdminNavCategoryProvider = (props) => {
    const [navCategory, setNavCategory] = useState('');

    return (
        <CatStoreSuperAdminNavCategoryContext.Provider value={[navCategory, setNavCategory]} >
            { props.children}
        </CatStoreSuperAdminNavCategoryContext.Provider>
    )
};