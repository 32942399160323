import React, { useEffect, useState, useContext } from "react";
import "./cat-store-my-account-page.styles.scss";
import { UserContext } from "../../contexts/user-provider/UserProvider";
import { firestore } from "../../../firebase/firebase.utils";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// All component imports go here
import CatStoreHeader from "../../components/cat-store-front-end/cat-store-header/cat-store-header.component";
import CatStoreMyAccount from "../../components/cat-store-front-end/cat-store-my-account/cat-store-my-account.component";
import CatStoreFooter from "../../components/cat-store-front-end/cat-store-footer/cat-store-footer.component";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffff",
  },
}));

const CatStoreMyAccountPage = () => {
  const classes = useStyles();
  let user = useContext(UserContext);
  const [userOrders, setUserOrders] = useState([]);
  const [userAddress, setUserAddress] = useState([]);
  const [open, setOpen] = useState(false);

  // Func to set backdrops
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  // effect to fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      await setOpen(true);
      const orderRef = await firestore
        .collection("users")
        .doc(`${user.uid}`)
        .collection("user-orders")
        .orderBy("orderTime", "desc");
      const addressRef = await firestore
        .collection("users")
        .doc(`${user.uid}`)
        .collection("user-addresses");
      await orderRef.get().then((snapshot) => {
        let data = [];
        snapshot.docs.map((doc) => data.push(doc.data()));
        setUserOrders(data);
        // console.log(data)
      });
      await addressRef
        .get()
        .then((snapshot) => {
          let addressData = [];
          snapshot.docs.map((doc) => addressData.push(doc.data()));
          setUserAddress(addressData);
          // console.log(data)
        })
        .then(() => setTimeout(() => setOpen(false), 800));
    };
    fetchUserData();
  }, []);

  console.log(userOrders);

  return (
    <div>
      <CatStoreHeader user={user} />
      <CatStoreMyAccount userOrders={userOrders} userAddress={userAddress} />
      <CatStoreFooter />
      {/* Backdrop */}
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default CatStoreMyAccountPage;
