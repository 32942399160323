import React, { createContext, Component, useState, useEffect } from 'react';
import { auth, generateUserDocument } from '../../../firebase/firebase.utils';

export const UserContext = createContext({ user: null });

const UserProvider = (props) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const setAuthChange = async () => {
            await auth.onAuthStateChanged(async userAuth => {
                const user = await generateUserDocument(userAuth);
                await setUser(user);
            });

        };
        setAuthChange();
    }, [])

    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserProvider;
