import React, { useState, useEffect } from "react";
import "./admin-profile.styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPortrait, faEdit } from "@fortawesome/free-solid-svg-icons";
import { firestore } from "../../../../firebase/firebase.utils";

import { Card, Button } from "react-bootstrap";

const ManageAdmins = () => {
  const [adminData, setAdminData] = useState([]);
  const [nameEditable, setNameEditable] = useState(false);
  const [userNameEditable, setUserNameEditable] = useState(false);
  const [passwordEditable, setPasswordEditable] = useState(false);

  const [nameInput, setNameInput] = useState("");
  const [userNameInput, setUserNameInput] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  // create a new admin
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [userExists, setUserExists] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const adminRef = await firestore.collection("admins");
      await adminRef.get().then((snapshot) => {
        let adminsData = [];

        snapshot.docs.map(async (doc) => {
          const id = {
            id: doc.id,
          };
          const admins = doc.data();
          let data = {
            ...admins,
            ...id,
          };
          await adminsData.push(data);

          await setAdminData(adminsData);
        });
      });
    };
    fetchData();
  }, []);

  // handle create admin
  const handleNewAdmin = async () => {
    if (name === "") return alert("Please provide a name");

    if (userName === "") return alert("Please provide a username");

    if (password === "") return alert("Please provide a password");

    if (password.length < 5)
      return alert("Minimum 5 Characters Required in the Password");

    setLoading(true);

    const adminRef = await firestore.collection("admins");

    await adminRef.get().then(async (snapshot) => {
      await snapshot.docs.map((doc) =>
        doc.data().userName.toLowerCase() === userName.toLowerCase()
          ? alert("You tried adding a Admin which is already available.")
          : setUserExists(false)
      );

      if (userExists) {
        return adminRef
          .add({
            userName: userName,
            password: password,
            name: name,
            role: "admin",
          })
          .then(async () => await alert("New Admin Has Been Added"))
          .then(async () => await window.location.reload(false));
      } else {
        return null;
      }
      setLoading(false);
    });
  };

  // Handle Name Change
  const handleNameChange = async () => {
    // const docRefUser = await firestore.collection("stores").doc("cat-store-user-profile");
    // if (nameInput) {
    //     await docRefUser.update({name: `${nameInput}`}).then(() => setNameEditable(!nameEditable));
    //     window.location.reload(false);
    // } else {
    //     setNameEditable(!nameEditable)
    // }
  };

  // Handle User Name Change
  const handleUserNameChange = async () => {
    // const docRefUser = await firestore.collection("stores").doc("cat-store-user-profile");
    // if (userNameInput) {
    //     await docRefUser.update({userName: `${userNameInput}`}).then(() => setUserNameEditable(!userNameEditable));
    //     window.location.reload(false);
    //     localStorage.clear()
    // } else {
    //     setUserNameEditable(!userNameEditable)
    // }
  };

  // Handle Password Change
  const handlePasswordChange = async (data) => {
    if (password1 !== password2) return alert("Passwords do not match");

    if (password1.length <= 5)
      return alert("Passwords length is no sufficient");

    const docRef = await firestore.collection("admins").doc(data.id);

    await docRef
      .update({ password: `${password1}` })
      .then(() => setPasswordEditable(!passwordEditable));
  };

  // delete admin
  const handleAdminDelete = async (data) => {
    const docRef = await firestore
      .collection("admins")
      .doc(data.id)
      .delete()
      .then(() => alert("Document deleted successfully"))
      .then(() => window.location.reload(false));
  };

  return (
    <div className="admin-profile-main-container">
      <h1>Manage Admins</h1>
      <br />
      <div className="p-4 bg-info rounded">
        <h3>Create a new Admin</h3>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Username</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter User Name"
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {loading ? (
          <div>loading</div>
        ) : (
          <button onClick={() => handleNewAdmin()} className="btn btn-primary">
            Create
          </button>
        )}{" "}
      </div>
      <br />
      <Card>
        <Card.Header>Manage Profile</Card.Header>
        <Card.Body>
          {" "}
          {/* name field handler */}
          {adminData.map((data) => (
            <>
              <FontAwesomeIcon
                className="fa-icon-admin-profile"
                icon={faPortrait}
              />
              <Card.Text>
                <div className="admin-profile-manage-profile">
                  {!nameEditable ? (
                    <span className="admin-profile-detail">
                      Name :
                      <em>
                        <b>{data.name} </b>
                      </em>
                      <FontAwesomeIcon
                        className="fa-inner-edit"
                        icon={faEdit}
                        onClick={() => setNameEditable(!nameEditable)}
                      />
                      <span
                        className="fa-inner-edit"
                        onClick={() => setNameEditable(!nameEditable)}
                      >
                        edit
                      </span>
                    </span>
                  ) : (
                    <div className="admin-inner-edit-container">
                      <input
                        type="text"
                        placeholder="Type New Username"
                        className="admin-inner-input"
                        defaultValue={`${data.name}`}
                        onChange={(event) => setNameInput(event.target.value)}
                      ></input>
                      <div>
                        <Button
                          className="admin-inner-button"
                          variant="info"
                          onClick={() => setNameEditable(!nameEditable)}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="admin-inner-button"
                          variant="warning"
                          onClick={() => handleNameChange()}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  )}
                  {/* Username Field Handler */}
                  {!userNameEditable ? (
                    <span className="admin-profile-detail">
                      Username :
                      <em>
                        <b>{data.userName} </b>
                      </em>
                      <FontAwesomeIcon
                        className="fa-inner-edit"
                        icon={faEdit}
                        onClick={() => setUserNameEditable(!userNameEditable)}
                      />
                      <span
                        className="fa-inner-edit"
                        onClick={() => setUserNameEditable(!userNameEditable)}
                      >
                        edit
                      </span>
                    </span>
                  ) : (
                    <div className="admin-inner-edit-container">
                      <input
                        type="text"
                        placeholder="Type New Username"
                        className="admin-inner-input"
                        defaultValue={`${data.userName}`}
                        onChange={(event) =>
                          setUserNameInput(event.target.value)
                        }
                      ></input>
                      <div>
                        <Button
                          className="admin-inner-button"
                          variant="info"
                          onClick={() => setUserNameEditable(!userNameEditable)}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="admin-inner-button"
                          variant="warning"
                          onClick={() => handleUserNameChange()}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  )}
                  {/* Password Field Handler */}
                  {!passwordEditable ? (
                    <span className="admin-profile-detail">
                      Password :<em>--hidden--</em>
                      <FontAwesomeIcon
                        className="fa-inner-edit"
                        icon={faEdit}
                        onClick={() => setPasswordEditable(!passwordEditable)}
                      />
                      <span
                        className="fa-inner-edit"
                        onClick={() => setPasswordEditable(!passwordEditable)}
                      >
                        change
                      </span>
                    </span>
                  ) : (
                    <div className="admin-inner-edit-container">
                      <input
                        type="password"
                        placeholder="New Password"
                        className="admin-inner-input"
                        onChange={(event) => setPassword1(event.target.value)}
                      />
                      <input
                        type="password"
                        placeholder="Re-type Password"
                        className="admin-inner-input"
                        onChange={(event) => setPassword2(event.target.value)}
                      />
                      <div>
                        <Button
                          className="admin-inner-button"
                          variant="info"
                          onClick={() => setPasswordEditable(!passwordEditable)}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="admin-inner-button"
                          variant="warning"
                          onClick={() => handlePasswordChange(data)}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  )}{" "}
                </div>
                <div>
                  <Button
                    className="admin-inner-button"
                    variant="danger"
                    onClick={() => handleAdminDelete(data)}
                  >
                    Delete
                  </Button>
                </div>
              </Card.Text>
              <div class="border-top my-3"></div>
            </>
          ))}{" "}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ManageAdmins;
