import React, { useContext } from "react";
import { UserContext } from "../../contexts/user-provider/UserProvider";
import "./cat-store-checkout-page.styles.scss";

// Comp imports
import CatStoreHeader from "../../components/cat-store-front-end/cat-store-header/cat-store-header.component";
import CatStoreFooter from "../../components/cat-store-front-end/cat-store-footer/cat-store-footer.component";
import CatStoreCheckout from "../../components/cat-store-front-end/cat-store-checkout/cat-store-checkout.component";

// import { CatStoreCartProvider } from '../../contexts/cart-items/cart-items.context';

const CatStoreCheckoutPage = () => {
  let user = useContext(UserContext);

  return (
    <>
      <CatStoreHeader user={user} />
      <div className="check-out-main-page">
        <CatStoreCheckout user={user} />
      </div>
      <CatStoreFooter />
    </>
  );
};

export default CatStoreCheckoutPage;
