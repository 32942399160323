import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import AdminEditProductModal from "../admin-edit-product-modal/admin-edit-product-modal.component";
import "./admin-product-card.styles.scss";
import firebase from "firebase/app";
import { firestore, imgStorage } from "../../../../firebase/firebase.utils";

import { Card } from "react-bootstrap";

const AdminProductCard = (props) => {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Handle Delete Function Done
  const handleProductDelete = async () => {
    const docRefDelete = await firestore
      .collection("online-store")
      .doc("products")
      .collection("product-list");
    docRefDelete.get().then(async (snapshot) => {
      let docId = "";
      await snapshot.docs.map((doc) =>
        doc.data().productId === props.productId ? (docId = doc.id) : null
      );
      const imgRef = await imgStorage
        .ref()
        .child(
          `image-src-cat-store-main/coorg-basket-product-images/${props.imageName}`
        );
      await imgRef.delete();
      await firestore
        .collection("online-store")
        .doc("products")
        .collection("product-list")
        .doc(`${docId}`)
        .delete()
        .then(() => alert("Document deleted successfully"))
        .then(handleClose)
        .then(() => window.location.reload(false));
    });
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h5>WARNING</h5>
        </Modal.Header>
        <Modal.Body>
          <h6>This will delete the selected product permanently, Proceed ?</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => handleProductDelete()}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="admin-product-card-container">
        <Card className="admin-product-card" style={{ width: "14rem" }}>
          <Card.Img
            className="admin-product-card-image"
            variant="top"
            src={`${props.imageUrl}`}
          />
          <Card.Body>
            <h6>{props.name}</h6>
            <span>Category : {props.category}</span>
            <br />
            <span>Parent Store : {props.parentStore}</span>
            <br />
            <br />
            <h6>Items In Stock : {props.availableStock}</h6>
            <span>Added On : {props.addedOn.toDate().toDateString()}</span>
            <br />
          </Card.Body>
          <Card.Body>
            <Card.Link href="#edit" onClick={() => setModalShow(true)}>
              Edit
            </Card.Link>
            <AdminEditProductModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              productId={props.productId}
              name={props.name}
              avail={props.avail}
              description={props.description}
              expiry={props.expiry}
              imageUrl={props.imageUrl}
              imageName={props.imageName}
              price={props.price}
              quantity={props.quantity}
              availableStock={props.availableStock}
              gst={props.gst}
              parentStore={props.parentStore}
              category={props.category}
              categoryMeta={props.categoryMeta}
              addedOn={props.addedOn}
              measure={props.measure}
            />
            <Card.Link href="#delete" onClick={handleShow}>
              Delete
            </Card.Link>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default AdminProductCard;
