import React from "react";
import "./App.css";

import Application from "./application/application";
import UserProvider from "./cat-store/contexts/user-provider/UserProvider";
import { CartProvider } from "./cat-store/contexts/cart-items/cart-items.context";

function App() {
  // const user = null;

  return (
    <div>
      <UserProvider>
        <CartProvider>
          <Application />
        </CartProvider>
      </UserProvider>
    </div>
  );
}

export default App;
