import React from 'react';
import './cat-store-my-account.styles.scss';
import { Tab, Tabs, Table } from "react-bootstrap";

const CatStoreMyAccount = ({ userOrders, userAddress }) => {
    const [key, setKey] = React.useState('my-orders');
    return (
        <div className="cat-store-my-account-container">
            <div className="my-account-header">
                <h2>My Orders</h2>
            </div>
            <div className="my-account-options-wrapper">
                <Tabs className="tab-view" activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab className="my-orders-tab" eventKey="my-orders" title="My Orders">
                        <div className="my-orders-pane">
                            {userOrders.length > 0 ?
                                <Table className="my-orders-table" responsive size="sm" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Order Id</th>
                                            <th>Placed On</th>
                                            <th>Order Items</th>
                                            <th>Order Total</th>
                                            <th>Delivery Address</th>
                                            <th>Order Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userOrders.map((item, i) =>
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{item.orderId}</td>
                                                <td>{item.orderTime.toDate().toDateString()}</td>
                                                <td>
                                                    {item.orderItems.map((item, i) =>
                                                        <tr className="inner-table">
                                                            <td>{i + 1}</td>
                                                            <td>{`${item.name} - ${item.quantity} ${item.measure}`} </td>
                                                            <td>{`₹ ${item.price} X ${item.cartQuantity}`} </td>
                                                            <td>{`₹ ${item.price * item.cartQuantity}`} </td>
                                                        </tr>
                                                    )}
                                                </td>
                                                <td>₹ {item.orderItems.reduce((accumalatedQuantity, item) => accumalatedQuantity + (Number(item.price) * Number(item.cartQuantity)), 0).toFixed(2)}</td>
                                                <td style={{ maxWidth: "150px" }}>{item.customerDetails.fullAddress}</td>
                                                <td>{item.orderStatus}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table> : <span style={{ color: "gray" }}>No Orders Yet</span>}
                        </div>
                    </Tab>
                    {/* <Tab className="my-address-tab" eventKey="my-address" title="My Address">
                        <div className="my-address-pane">
                            {userAddress.map((item, i) =>
                                <span key={i} className="address-block">
                                    {item.fullAddress}
                                </span>
                            )}
                        </div>
                    </Tab> */}
                </Tabs>
            </div>
        </div>
    )
}

export default CatStoreMyAccount;
