import React, { useState, useEffect } from 'react';
import './cat-store-admin-page.styles.scss';

import AdminHeader from '../../components/admin-components/admin-header/admin-header.component';
import AdminFooter from '../../components/admin-components/admin-footer/admin-footer.component';
import AdminComponentView from '../../components/admin-components/admin-component-view/admin-component-view.component';
import AdminLogin from '../../components/admin-components/admin-login/admin-login.component';


const CatStoreAdminPage = () => {
    // Change this to auth later
    const [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem('loggedIn')));
    const [whichAdmin, setWhichAdmin] = useState(localStorage.getItem('store'));

    // Effect store view in local storage
    useEffect(() => {
        return setIsLoggedIn(Boolean(localStorage.getItem('loggedIn')))
    }, []);

    return (
        <div className='cat-store-admin-main-container'>
            {
                isLoggedIn === false ?
                    <>
                        <AdminLogin setIsLoggedIn={(isLoggedIn) => setIsLoggedIn(isLoggedIn)} setWhichAdmin={(whichAdmin) => setWhichAdmin(whichAdmin)} />
                    </>
                    :
                    <>
                        {whichAdmin === 'cat-main' ?
                            <>
                                <AdminHeader setIsLoggedIn={(isLoggedIn) => setIsLoggedIn(isLoggedIn)} setWhichAdmin={(whichAdmin) => setWhichAdmin(whichAdmin)} />
                                <AdminComponentView />
                                <AdminFooter />
                            </>
                            :
                            null
                        }
                    </>
            }
        </div>
    )
}

export default CatStoreAdminPage;