import React, { useState, useEffect } from 'react';
import './cat-store-admin-page.styles.scss';

import SuperAdminHeader from '../../components/super-admin/super-admin-header/super-admin-header.component';
import AdminFooter from '../../components/admin-components/admin-footer/admin-footer.component';
import SuperAdminComponentView from '../../components/super-admin/super-admin-component-view/super-admin-component-view.component';
import SuperAdminLogin from '../../components/super-admin/super-admin-login/super-admin-login.component';


const CatStoreSuperAdminPage = () => {
    // Change this to auth later
    const [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem('loggedIn')));
    const [whichAdmin, setWhichAdmin] = useState(localStorage.getItem('store'));

    // Effect store view in local storage
    useEffect(() => {
        return setIsLoggedIn(Boolean(localStorage.getItem('loggedIn')))
    }, []);

    return (
        <div className='cat-store-admin-main-container'>
            {
                isLoggedIn === false ?
                    <>
                        <SuperAdminLogin setIsLoggedIn={(isLoggedIn) => setIsLoggedIn(isLoggedIn)} setWhichAdmin={(whichAdmin) => setWhichAdmin(whichAdmin)} />
                    </>
                    :
                    <>
                        {whichAdmin === 'super-admin' ?
                            <>
                                <SuperAdminHeader setIsLoggedIn={(isLoggedIn) => setIsLoggedIn(isLoggedIn)} setWhichAdmin={(whichAdmin) => setWhichAdmin(whichAdmin)} />
                                <SuperAdminComponentView />
                                <AdminFooter />
                            </>
                            :
                            null
                        }
                    </>
            }
        </div>
    )
}

export default CatStoreSuperAdminPage;