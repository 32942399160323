import React from 'react';
import './admin-header.styles.scss';
import firebase from 'firebase/app';
import catStoreLogo from '../../../../assets/cat-store-main-logo.png';
import { Button } from 'react-bootstrap';

const AdminHeader = ({ setIsLoggedIn }) => {

    // handle logout
    const handleLogout = () => {
        localStorage.clear();
        return setIsLoggedIn(false);
    }

    return (
        <div className='admin-header-main-container'>
            <div className='admin-logo-container'><img src={catStoreLogo} alt='cat-admin-logo' /></div>
            <div className='admin-logout-button-container'>
                <Button className='admin-logout-button' variant={'danger'} onClick={() => handleLogout()}>Logout</Button>
            </div>
        </div>
    )
}

export default AdminHeader;