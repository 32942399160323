import React, { useEffect, useState } from "react";
import "./admin-component-view.styles.scss";

import AdminLeftNavigation from "../admin-left-navigation/admin-left-navigation.component";
import AdminDashboard from "../admin-dashboard/admin-dashboard.component";
import AdminCategories from "../admin-categories/admin-categories.component";
import AdminProducts from "../admin-products/admin-products.component";
import AdminOrders from "../admin-orders/admin-orders.component";
import AdminProfile from "../admin-profile/admin-profile.component";
import { firestore } from "../../../../firebase/firebase.utils";

const AdminComponentView = () => {
  const [shopData, setShopData] = useState([]);
  const [currentComponent, setCurrentComponent] = useState("");
  const [categoryMeta, setcategoryMeta] = useState([]);
  const [mobileMenuShow, setMobileMenuShow] = useState(false);

  // Effect to fetch all shop data
  useEffect(() => {
    const fetchShopData = async () => {
      const docRef = await firestore
        .collection("online-store")
        .doc("products")
        .collection("product-list");
      docRef.get().then((snapshot) => {
        let data = [];
        snapshot.docs.map((doc) => data.push(doc.data()));
        setShopData(data);
      });
    };
    fetchShopData();
  }, [setShopData]);

  // Effect to fetch category and sub category meta data
  useEffect(() => {
    const fetchCategoryData = async () => {
      const categoryRef = await firestore
        .collection("online-store")
        .doc("categories")
        .collection("category-meta-data");
      categoryRef.get().then((snapshot) => {
        let catData = [];

        snapshot.docs.map(async (doc) => {
          await catData.push(doc.data());
          await setcategoryMeta(catData);
        });
      });
    };
    fetchCategoryData();
  }, [setcategoryMeta]);

  // Effect store view in local storage
  useEffect(() => {
    const getLocatStorage = () => {
      localStorage.getItem("currentComponentLocal") === null
        ? setCurrentComponent(
            localStorage.setItem("currentComponentLocal", "dashboard")
          )
        : setCurrentComponent(localStorage.getItem("currentComponentLocal"));
    };
    getLocatStorage();
  }, [currentComponent]);

  return (
    <div className="admin-component-view-main-container">
      <div
        className="admin-mobile-menu"
        onClick={() => setMobileMenuShow(!mobileMenuShow)}
      >
        MENU
      </div>
      <div className="admin-view-left-navigation">
        <AdminLeftNavigation
          shopData={shopData}
          setCurrentComponent={(currentComponent) =>
            setCurrentComponent(currentComponent)
          }
          currentComponent={currentComponent}
          categoryMeta={categoryMeta}
        />
      </div>
      {mobileMenuShow ? (
        <>
          <div
            className="admin-mobile-menu"
            onClick={() => setMobileMenuShow(!mobileMenuShow)}
          >
            CLOSE
          </div>
          <div className="admin-view-left-mobile-navigation">
            <AdminLeftNavigation
              shopData={shopData}
              setCurrentComponent={(currentComponent) =>
                setCurrentComponent(currentComponent)
              }
              currentComponent={currentComponent}
              categoryMeta={categoryMeta}
            />
          </div>
        </>
      ) : null}
      <div className="admin-right-view-port">
        {currentComponent === "dashboard" ? (
          <AdminDashboard shopData={shopData} categoryMeta={categoryMeta} />
        ) : null}
        {currentComponent === "categories" ? (
          <AdminCategories shopData={shopData} categoryMeta={categoryMeta} />
        ) : null}
        {currentComponent === "products" ? (
          <AdminProducts shopData={shopData} categoryMeta={categoryMeta} />
        ) : null}
        {currentComponent === "orders" ? <AdminOrders /> : null}
        {currentComponent === "profile" ? <AdminProfile /> : null}
      </div>
    </div>
  );
};

export default AdminComponentView;
