import React, { useState, useEffect } from "react";
import "./admin-add-new-product-modal.styles.scss";
import firebase from "firebase/app";
import { Modal, Form, Button } from "react-bootstrap";
import { imgStorage, firestore } from "../../../../firebase/firebase.utils";

const AdminAddNewProductModal = (props) => {
  const { categoryMeta } = props;

  const [productIdGen, setProductIdGen] = useState("");
  const [imageDisplay, setImageDisplay] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState("");

  // Effect to generate random code
  useEffect(() => {
    const onLoadGenRandCode = async () => {
      let randomCode = "";
      const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for (var i = 12; i > 0; --i)
        randomCode += chars[Math.floor(Math.random() * chars.length)];
      await setProductIdGen(randomCode);
    };
    onLoadGenRandCode();
  }, []);

  const uploadInfo = {
    productId: productIdGen,
    name: "",
    avail: true,
    description: "",
    expiry: "",
    imageUrl: "",
    price: Number(0),
    quantity: Number(0),
    availableStock: Number(0),
    gst: Number(0),
    parentStore: "cat kodagu",
    category: "",
    addedOn: firebase.firestore.FieldValue.serverTimestamp(),
    measure: "",
    imageName: "",
  };

  const handleSaveChanges = async () => {
    if (fileName === null) {
      setError("Please upload a image");
    } else if (uploadInfo.name === "") {
      setError("Provide a Product name");
    } else if (uploadInfo.price === 0) {
      setError("Provide Product Price");
    } else if (uploadInfo.quantity === 0) {
      setError("Provide Product quantity");
    } else if (uploadInfo.measure === "") {
      setError("Select product measurement");
    } else if (uploadInfo.availableStock === 0) {
      setError("Enter the stock");
    } else if (uploadInfo.category === "") {
      setError("Select product  category");
    } else if (uploadInfo.description === "") {
      setError("Provide Product description");
    } else if (uploadInfo.description === "") {
      setError("Provide Product description");
    } else {
      var blob = new Blob([file], { type: "image/jpeg" });
      const folderRef = await imgStorage
        .ref()
        .child("image-src-cat-store-main/coorg-basket-product-images");
      const fileRef = await folderRef.child(`${fileName}`);
      await fileRef.put(blob);
      await fileRef
        .getDownloadURL()
        .then((url) => (uploadInfo.imageUrl = url))
        .then(() => (uploadInfo.imageName = fileName))
        .then(() => alert("File Uploaded"));
      const docId = [];
      await firestore
        .collection("online-store")
        .doc("products")
        .collection("product-list")
        .add(uploadInfo)
        .then(async function (docRef) {
          await docId.push(docRef.id);
          await alert("Product Added");
        })
        .catch(async function (error) {
          await alert("Error adding document: ", error);
        })
        .finally(async () => await window.location.reload(false));
      await props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      size="xl"
      className="admin-add-products-modal-parent-container"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h6>Add New Product</h6>
      </Modal.Header>
      <Modal.Body>
        <Form className="admin-add-products-modal-form">
          <div className="admin-add-image-wrapper d-flex flex-row justify-content-between align-items-center">
            <h4>Upload Image</h4>
            <Form.File
              className="admin-add-product-image-upload-field"
              id="exampleFormControlFile1"
              onChange={(event) => {
                setFile(event.target.files[0]);
                setImageDisplay(URL.createObjectURL(event.target.files[0]));
                setFileName(event.target.files[0].name);
              }}
            />
            {/* <Button
              className="admin-upload-image-button"
              variant={"info"}
              onClick={() => handleUpload()}
            >
              Upload Image
            </Button> */}
            <img src={imageDisplay} width="100" height="100" />
          </div>

          <div className="admin-add-product-name-wrapper">
            <Form.Label className="admin-add-product-label">
              Product Name
            </Form.Label>
            <Form.Control
              className="admin-add-product-form-name"
              type="text"
              placeholder="Full Product Name"
              onChange={(event) => (uploadInfo.name = event.target.value)}
            />
          </div>

          <div className="admin-add-product-price-quantity-wrapper">
            <div>
              <Form.Label className="admin-add-product-label">
                Price MRP in ₹
              </Form.Label>
              <Form.Control
                className="admin-add-product-price"
                type="text"
                placeholder="Price in ₹"
                onChange={(event) =>
                  (uploadInfo.price = Number(event.target.value))
                }
              />
            </div>
            <div>
              <Form.Label className="admin-add-product-label">
                Quantity
              </Form.Label>
              <Form.Control
                className="admin-add-product-quantity"
                type="text"
                placeholder="Quantity eg: 500, 1, 10"
                onChange={(event) => (uploadInfo.quantity = event.target.value)}
              />
            </div>
            <div>
              <Form.Label className="admin-add-product-label">
                Select Measure
              </Form.Label>
              <Form.Control
                className="admin-add-product-quantity"
                as="select"
                onChange={(event) => (uploadInfo.measure = event.target.value)}
              >
                <option selected>{props.measure}</option>
                <option>kg</option>
                <option>gm</option>
                <option>lt</option>
                <option>ml</option>
                <option>packet/s</option>
                <option>number/s</option>
              </Form.Control>
            </div>
            <div>
              <Form.Label className="admin-add-product-label">
                Items In Stock
              </Form.Label>
              <Form.Control
                className="admin-add-product-stock"
                type="text"
                placeholder="10 / 20 / 30"
                onChange={(event) =>
                  (uploadInfo.availableStock = Number(event.target.value))
                }
              />
            </div>
            <div>
              <Form.Label className="admin-add-product-label">
                Item GST %
              </Form.Label>
              <Form.Control
                className="admin-add-product-quantity"
                as="select"
                onChange={(event) => (uploadInfo.gst = event.target.value)}
              >
                <option selected>{props.gst}</option>
                <option>0</option>
                <option>5</option>
                <option>12</option>
                <option>18</option>
                <option>28</option>
              </Form.Control>
            </div>
          </div>

          <div className="form-category-admin-wrapper">
            <div className="form-category-block">
              <Form.Label className="admin-add-product-label">
                Select Category
              </Form.Label>
              <Form.Control
                className="admin-add-product-category-select-field"
                as="select"
                onChange={(event) => (uploadInfo.category = event.target.value)}
              >
                <option selected>{null}</option>
                {categoryMeta.map((item, i) => (
                  <option>{item.category}</option>
                ))}
              </Form.Control>
            </div>
          </div>
          <div className="admin-text-area-group">
            <div>
              <Form.Label className="admin-add-product-label">
                Product Description
              </Form.Label>
              <Form.Control
                className="admin-add-product-description-area-field"
                as="textarea"
                rows="2"
                onChange={(event) =>
                  (uploadInfo.description = event.target.value)
                }
              />
            </div>
            <div>
              <Form.Label className="admin-add-product-label">
                Expiry
              </Form.Label>
              <Form.Control
                className="admin-add-product-expiry-field"
                as="textarea"
                rows="2"
                onChange={(event) => (uploadInfo.expiry = event.target.value)}
              />
            </div>
          </div>
        </Form>
        <Modal.Footer>
          <div className="pr-5">
            <p className="text-danger">{error}</p>
          </div>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              await handleSaveChanges();
            }}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default AdminAddNewProductModal;
