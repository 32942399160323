import React, { useEffect, useContext } from 'react';
import './cat-store-category-items.styles.scss';
import { Link, useHistory } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { CartContext } from '../../../contexts/cart-items/cart-items.context';

const CatStoreCategoryItems = ({ catProducts }) => {
    let history = useHistory();
    const [cart, setCart] = useContext(CartContext);
    // effect to scroll to top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="category-list-view-container">
            {catProducts.length > 0 ?
                <div className="category-list-inner-wrapper">
                    <div className="category-list-header">
                        <span className="category-heading">{catProducts[0].category}</span>
                    </div>
                    <div className="card-view-cat-items">
                        {catProducts.map((item, i) =>
                            <Card key={i} className="card-main-container">
                                <CardActionArea onClick={() => history.push(`/product/${item.name.toString().replace(/[^a-zA-Z ]/g, "").split(' ').join('-')}/${item.productId}`)}>
                                    <CardMedia className="card-image" image={`${item.imageUrl}`} title="Contemplative Reptile" />
                                    <CardContent className="card-content">
                                        <h3>{item.name}</h3>
                                        <span className="category">{item.category}</span>
                                        <div className="card-details-container">
                                            <span className="quantity">{item.quantity} {item.measure}</span>
                                            <span className="price">₹ {item.price}</span>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions style={{ display: "flex", justifyContent: "space-between" }}>
                                    {cart.find(finItem => item.productId === finItem.productId) === undefined ?
                                        <Button className="add-to-cart-button" size="small" color="primary" onClick={() => setCart([...cart, {
                                            name: item.name,
                                            availableStock: item.availableStock,
                                            category: item.category,
                                            gst: item.gst,
                                            quantity: item.quantity,
                                            price: item.price,
                                            productId: item.productId,
                                            imageUrl: item.imageUrl,
                                            measure: item.measure,
                                            cartQuantity: 1,
                                            parentStore: item.parentStore
                                        }])}>Add To Cart</Button>
                                        :
                                        <div className="quantity-buttons">
                                            <span className="remove" onClick={() => {
                                                const elementsIndex = cart.findIndex(element => element.productId == item.productId);
                                                let editArray = [...cart];
                                                if (editArray[elementsIndex].cartQuantity === 1) {
                                                    setCart(editArray.filter(selItem => selItem.productId !== item.productId))
                                                } else if (editArray[elementsIndex].cartQuantity >= 1) {
                                                    editArray[elementsIndex] = {
                                                        ...editArray[elementsIndex],
                                                        cartQuantity: editArray[elementsIndex].cartQuantity - 1
                                                    };
                                                    setCart(editArray);
                                                }
                                            }} >-</span>
                                            <span className="value">{cart.find(finItem => item.productId === finItem.productId).cartQuantity}</span>
                                            <span className='add' onClick={() => {
                                                const elementsIndex = cart.findIndex(element => element.productId == item.productId);
                                                let editArray = [...cart];
                                                editArray[elementsIndex] = { ...editArray[elementsIndex], cartQuantity: editArray[elementsIndex].cartQuantity + 1 };
                                                setCart(editArray)
                                            }}>+</span>
                                        </div>}
                                    <Link to={`/product/${item.name.toString().replace(/[^a-zA-Z ]/g, "").split(' ').join('-')}/${item.productId}`}><Button className="add-to-cart-button" size="small" color="primary">Details</Button></Link>
                                </CardActions>
                            </Card>
                        )}
                    </div>
                </div>

                : <div className="category-list-inner-wrapper empty"><span>This Category Doesn't Contain Any Products Yet!!</span></div>}
        </div>
    )
}

export default CatStoreCategoryItems;
