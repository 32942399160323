import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./cat-store-header.styles.scss";
import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import { auth } from "../../../../firebase/firebase.utils";
import MainLogo from "../../../../assets/cat-store-main-logo.png";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { CartContext } from "../../../contexts/cart-items/cart-items.context";
import { firestore } from "../../../../firebase/firebase.utils";

//import utils
import InstallPWA from "../../../../utils/InstallPwa";

// Item List

const CatStoreHeader = ({ user }) => {
  let history = useHistory();
  const [cart, setCart] = useContext(CartContext);
  const [categoryMeta, setcategoryMeta] = useState([]);
  const [storeDetails, setStoreDetails] = useState([]);
  // modal controls
  const [showCart, setShowCart] = useState(false);

  const handleClose = () => setShowCart(false);
  const handleShow = () => setShowCart(true);

  // Effect to fetch category and sub category meta data
  useEffect(() => {
    const fetchCategoryData = async () => {
      const categoryRef = await firestore
        .collection("online-store")
        .doc("categories")
        .collection("category-meta-data");
      categoryRef.get().then((snapshot) => {
        let catData = [];
        snapshot.docs.map(async (doc) => {
          await catData.push(doc.data());
          await setcategoryMeta(catData);
        });
        // console.log(catData)
      });
    };
    fetchCategoryData();
  }, [setcategoryMeta]);

  // Effect to fetch store names
  useEffect(() => {
    const fetchStoreData = async () => {
      const categoryRef = await firestore
        .collection("online-store")
        .doc("provider-details")
        .collection("outside-stores");
      categoryRef.get().then((snapshot) => {
        let storeData = [];
        snapshot.docs.map(async (doc) => {
          await storeData.push(doc.data());
          await setStoreDetails(storeData);
        });
      });
    };
    fetchStoreData();
  }, [setStoreDetails]);

  // handle logout
  const handleLogout = () => {
    auth.signOut();
    history.push("/login");
  };

  return (
    <Navbar
      className="navbar-main-container "
      sticky="top"
      bg="light"
      expand="lg"
    >
      <Navbar.Brand onClick={() => history.push("/")}>
        <img width="60px" src={MainLogo} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav " />
      <Navbar.Collapse className="basic-navbar-nav   p-2 ">
        <Nav className="mr-auto">
          <Nav.Link className="nav-item" onClick={() => history.push("/home")}>
            Home
          </Nav.Link>
          {/* <NavDropdown className="nav-item dropdown" title="Categories" id="responsive-navbar-nav">
                        <div className="dropdown-items">
                            {categoryMeta.map((item, i) =>
                                <NavDropdown.Item key={i} href={`/category/${item.category}`} >{item.category}</NavDropdown.Item>
                            )}
                        </div>
                    </NavDropdown> */}
          {/* <NavDropdown className="nav-item dropdown" title="Stores" id="responsive-navbar-nav">
                        <div className="dropdown-items">
                            {storeDetails.map((item, i) =>
                                <NavDropdown.Item key={i} href={`/store/${item.storeName}`} >{item.storeName}</NavDropdown.Item>
                            )}
                        </div>
                    </NavDropdown> */}

          {user && (
            <Nav.Link
              className="nav-item"
              onClick={() => history.push("/my-account")}
            >
              My Orders
            </Nav.Link>
          )}
        </Nav>

        <InstallPWA />
        <div className="ml-3 my-3">
          <span
            style={{
              marginRight: "40px",
              position: "relative",
              cursor: "pointer",
            }}
            onClick={handleShow}
          >
            <span
              style={{
                fontSize: "1rem",
                position: "absolute",
                marginLeft: "35px",
                marginTop: "-12px",
              }}
            >
              {cart.reduce(
                (accumalatedQuantity, item) =>
                  accumalatedQuantity + Number(item.cartQuantity),
                0
              )}
            </span>
            <ShoppingCartOutlinedIcon fontSize="large" />
          </span>
        </div>
        <div className="mr-3">
          {user ? (
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => handleLogout()}
            >
              Logout
            </Button>
          ) : (
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => history.push("/login")}
            >
              Log In
            </Button>
          )}
        </div>
      </Navbar.Collapse>
      {/* Cart Modal */}
      <Modal
        show={showCart}
        size="xl"
        centered
        onHide={handleClose}
        className="cart-modal"
      >
        <Modal.Header closeButton>
          <h5>Your Cart</h5>
        </Modal.Header>
        <Modal.Body className="cart-modal-body">
          {cart.length > 0 ? (
            <Table size="md" hover scrollable responsive>
              <tbody>
                {cart.map((item, i) => (
                  <tr key={i}>
                    <td className="column-item">{i + 1}</td>
                    <td className="column-item">
                      <img src={item.imageUrl} height="75px" alt="url"></img>
                    </td>
                    <td className="column-item">{item.name}</td>
                    <td className="column-item">{item.parentStore}</td>
                    <td className="column-item">
                      {item.quantity} {item.measure}
                    </td>
                    <td className="column-item">
                      ₹{Number(item.price).toFixed(2)} X {item.cartQuantity}
                    </td>
                    <td className="column-item">
                      <div className="quantity-buttons">
                        <span
                          className="remove"
                          onClick={() => {
                            const elementsIndex = cart.findIndex(
                              (element) => element.productId == item.productId
                            );
                            let editArray = [...cart];
                            if (editArray[elementsIndex].cartQuantity === 1) {
                              setCart(
                                editArray.filter(
                                  (selItem) =>
                                    selItem.productId !== item.productId
                                )
                              );
                            } else if (
                              editArray[elementsIndex].cartQuantity >= 1
                            ) {
                              editArray[elementsIndex] = {
                                ...editArray[elementsIndex],
                                cartQuantity:
                                  editArray[elementsIndex].cartQuantity - 1,
                              };
                              setCart(editArray);
                            }
                          }}
                        >
                          -
                        </span>
                        <span className="value">
                          {
                            cart.find(
                              (finItem) => item.productId === finItem.productId
                            ).cartQuantity
                          }
                        </span>
                        <span
                          className="add"
                          onClick={() => {
                            const elementsIndex = cart.findIndex(
                              (element) => element.productId == item.productId
                            );
                            let editArray = [...cart];
                            editArray[elementsIndex] = {
                              ...editArray[elementsIndex],
                              cartQuantity:
                                editArray[elementsIndex].cartQuantity + 1,
                            };
                            setCart(editArray);
                          }}
                        >
                          +
                        </span>
                      </div>
                    </td>
                    <td className="column-item">
                      ₹
                      {(Number(item.price) * Number(item.cartQuantity)).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="6"></td>
                  <td className="column-item total">Total :</td>
                  <td className="column-item total">
                    ₹
                    {cart
                      .reduce(
                        (accumalatedQuantity, item) =>
                          accumalatedQuantity +
                          Number(item.price) * Number(item.cartQuantity),
                        0
                      )
                      .toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            "No Items in Your Cart Yet!!"
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => (history.push("/home"), setShowCart(false))}
          >
            Continue Shopping
          </Button>
          {cart.length > 0 ? (
            <Button
              variant="primary"
              onClick={() => {
                user ? history.push("/checkout") : history.push("/login");
              }}
            >
              Proceed To Checkout
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </Navbar>
  );
};

export default CatStoreHeader;
