import React, { useState, useEffect } from "react";
import "./admin-profile.styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPortrait, faEdit } from "@fortawesome/free-solid-svg-icons";
import { firestore } from "../../../../firebase/firebase.utils";

import { Card, Button } from "react-bootstrap";

const AdminProfile = () => {
  // const [adminProfile, setAdminProfile] = useState({});
  // const [nameEditable, setNameEditable] = useState(false);
  // const [userNameEditable, setUserNameEditable] = useState(false);
  // const [passwordEditable, setPasswordEditable] = useState(false);

  // const [nameInput, setNameInput] = useState("");
  // const [userNameInput, setUserNameInput] = useState("");
  // const [password1, setPassword1] = useState("");
  // const [password2, setPassword2] = useState("");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const docRefUser = await firestore.collection("stores").doc("admins");
  //     await docRefUser.get().then((doc) => setAdminProfile(doc.data()));
  //   };
  //   fetchData();
  // }, [setAdminProfile]);

  // // Handle Name Change
  // const handleNameChange = async () => {
  //   const docRefUser = await firestore.collection("stores").doc("admins");
  //   if (nameInput) {
  //     await docRefUser
  //       .update({ userName: `${nameInput}` })
  //       .then(() => setNameEditable(!nameEditable));
  //     window.location.reload(false);
  //   } else {
  //     setNameEditable(!nameEditable);
  //   }
  // };

  // //  Handle User Name Change
  // const handleUserNameChange = async () => {
  //   const docRefUser = await firestore.collection("stores").doc("admins");
  //   if (userNameInput) {
  //     await docRefUser
  //       .update({ userName: `${userNameInput}` })
  //       .then(() => setUserNameEditable(!userNameEditable));
  //     window.location.reload(false);
  //     localStorage.clear();
  //   } else {
  //     setUserNameEditable(!userNameEditable);
  //   }
  // };

  // //  Handle Password Change
  // const handlePasswordChange = async () => {
  //   const docRefUser = await firestore.collection("stores").doc("admins");
  //   if (
  //     password1 &&
  //     password2 &&
  //     password1 === password2 &&
  //     password1.length >= 8
  //   ) {
  //     await docRefUser
  //       .update({ password: `${password1}` })
  //       .then(() => setPasswordEditable(!passwordEditable));
  //     window.location.reload(false);
  //     localStorage.clear();
  //   } else if (password1 !== password2) {
  //     alert("Passwords Do NOT Match");
  //   } else if (password1.length < 8) {
  //     alert("Minimum 8 Characters Required in the Password");
  //   } else {
  //     setPasswordEditable(!passwordEditable);
  //   }
  // };

  return (
    <div className="admin-profile-main-container">
      <h1>Profile</h1>
      <br />
      <br />
    </div>
  );
};

export default AdminProfile;
