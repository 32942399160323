import React, { useState, createContext } from 'react';


export const OmStoreAdminNavCategoryContext = createContext();

export const OmStoreAdminNavCategoryProvider = (props) => {
    const [navCategory, setNavCategory] = useState('');

    return (
        <OmStoreAdminNavCategoryContext.Provider value={[navCategory, setNavCategory]} >
            { props.children}
        </OmStoreAdminNavCategoryContext.Provider>
    )
};