import React, { useEffect, useState } from "react";
import "./admin-component-view.styles.scss";

import SuperAdminLeftNavigation from "../../super-admin/super-admin-left-navigation/super-admin-left-navigation.component";
import SuperAdminDashboard from "../../super-admin/super-admin-dashboard/super-admin-dashboard.component";
import SuperAdminCategories from "../../super-admin/super-admin-categories/super-admin-categories.component";
import SuperAdminProducts from "../../super-admin/super-admin-products/super-admin-products.component";
import SuperAdminOrders from "../../super-admin/super-admin-orders/super-admin-orders.component";
import ManageAdmins from "../super-admin-manage-admins/super-admin-manage-admins.component";
import { firestore } from "../../../../firebase/firebase.utils";

const SuperAdminComponentView = () => {
  const [shopData, setShopData] = useState([]);
  const [currentComponent, setCurrentComponent] = useState("");
  const [categoryMeta, setcategoryMeta] = useState([]);
  const [mobileMenuShow, setMobileMenuShow] = useState(false);

  // Effect to fetch all shop data
  useEffect(() => {
    const fetchShopData = async () => {
      const docRef = await firestore
        .collection("online-store")
        .doc("products")
        .collection("product-list");
      docRef.get().then((snapshot) => {
        let data = [];
        snapshot.docs.map((doc) => data.push(doc.data()));
        setShopData(data);
      });
    };
    fetchShopData();
  }, [setShopData]);

  // Effect to fetch category and sub category meta data
  useEffect(() => {
    const fetchCategoryData = async () => {
      const categoryRef = await firestore
        .collection("online-store")
        .doc("categories")
        .collection("category-meta-data");
      categoryRef.get().then((snapshot) => {
        let catData = [];

        snapshot.docs.map(async (doc) => {
          await catData.push(doc.data());
          await setcategoryMeta(catData);
        });
      });
    };
    fetchCategoryData();
  }, [setcategoryMeta]);

  // Effect store view in local storage
  useEffect(() => {
    const getLocatStorage = () => {
      localStorage.getItem("currentComponentLocal") === null
        ? setCurrentComponent(
            localStorage.setItem("currentComponentLocal", "dashboard")
          )
        : setCurrentComponent(localStorage.getItem("currentComponentLocal"));
    };
    getLocatStorage();
  }, [currentComponent]);

  return (
    <div className="admin-component-view-main-container">
      <div
        className="admin-mobile-menu"
        onClick={() => setMobileMenuShow(!mobileMenuShow)}
      >
        MENU
      </div>
      <div className="admin-view-left-navigation">
        <SuperAdminLeftNavigation
          shopData={shopData}
          setCurrentComponent={(currentComponent) =>
            setCurrentComponent(currentComponent)
          }
          currentComponent={currentComponent}
          categoryMeta={categoryMeta}
        />
      </div>
      {mobileMenuShow ? (
        <>
          <div
            className="admin-mobile-menu"
            onClick={() => setMobileMenuShow(!mobileMenuShow)}
          >
            CLOSE
          </div>
          <div className="admin-view-left-mobile-navigation">
            <SuperAdminLeftNavigation
              shopData={shopData}
              setCurrentComponent={(currentComponent) =>
                setCurrentComponent(currentComponent)
              }
              currentComponent={currentComponent}
              categoryMeta={categoryMeta}
            />
          </div>
        </>
      ) : null}
      <div className="admin-right-view-port">
        {currentComponent === "dashboard" ? (
          <SuperAdminDashboard shopData={shopData} categoryMeta={categoryMeta} />
        ) : null}
        {currentComponent === "categories" ? (
          <SuperAdminCategories shopData={shopData} categoryMeta={categoryMeta} />
        ) : null}
        {currentComponent === "products" ? (
          <SuperAdminProducts shopData={shopData} categoryMeta={categoryMeta} />
        ) : null}
        {currentComponent === "orders" ? <SuperAdminOrders /> : null}
        {currentComponent === "adminusers" ? <ManageAdmins /> : null}
      </div>
    </div>
  );
};

export default SuperAdminComponentView;
