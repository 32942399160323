import React, { useState, useEffect } from "react";
import "./admin-edit-product-modal.styles.scss";
import { Modal, Form, Button } from "react-bootstrap";
import { imgStorage, firestore } from "../../../../firebase/firebase.utils";

const AdminEditProductModal = (props) => {
  const [imageDisplay, setImageDisplay] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState("");
  const [uploadInfo, setUploadInfo] = useState({
    productId: props.productId,
    name: props.name,
    avail: Number(props.avail),
    description: props.description,
    expiry: props.expiry,
    imageUrl: props.imageUrl,
    imageName: props.imageName,
    price: Number(props.price),
    quantity: Number(props.quantity),
    availableStock: Number(props.availableStock),
    gst: Number(props.gst),
    parentStore: props.parentStore,
    category: props.category,
    addedOn: props.addedOn,
    measure: props.measure,
  });

  const handleUpload = async () => {
    var blob = new Blob([file], { type: "image/jpeg" });
    const folderRef = await imgStorage
      .ref()
      .child("image-src-cat-store-main/coorg-basket-product-images");
    const fileRef = await folderRef.child(`${fileName}`);
    await fileRef.put(blob);
    await fileRef
      .getDownloadURL()
      .then((url) => (uploadInfo.imageUrl = url))
      .then(() => (uploadInfo.imageName = fileName))
      .then(() => alert("File Uploaded"));
  };

  console.log(uploadInfo);

  const handleEditProduct = async () => {
    if (!uploadInfo.imageUrl) {
      setError("Please upload a image");
    } else if (uploadInfo.name === "") {
      setError("Provide a Product name");
    } else if (uploadInfo.price === 0) {
      setError("Provide Product Price");
    } else if (uploadInfo.quantity === 0) {
      setError("Provide Product quantity");
    } else if (uploadInfo.measure === "") {
      setError("Select product measurement");
    } else if (uploadInfo.availableStock === 0) {
      setError("Enter the stock");
    } else if (uploadInfo.category === "") {
      setError("Select product  category");
    } else if (uploadInfo.description === "") {
      setError("Provide Product description");
    } else if (uploadInfo.description === "") {
      setError("Provide Product description");
    } else {
      const docRef = await firestore
        .collection("online-store")
        .doc("products")
        .collection("product-list");
      docRef.get().then(async (snapshot) => {
        let docId = "";
        await snapshot.docs.map((doc) =>
          doc.data().productId === props.productId ? (docId = doc.id) : null
        );
        await firestore
          .collection("online-store")
          .doc("products")
          .collection("product-list")
          .doc(`${docId}`)
          .update({
            name: uploadInfo.name,
            avail: Boolean(uploadInfo.avail),
            description: uploadInfo.description,
            expiry: uploadInfo.expiry,
            imageUrl: uploadInfo.imageUrl,
            imageName: uploadInfo.imageName,
            price: Number(uploadInfo.price),
            quantity: Number(uploadInfo.quantity),
            availableStock: Number(uploadInfo.availableStock),
            gst: Number(uploadInfo.gst),
            parentStore: uploadInfo.parentStore,
            category: uploadInfo.category,
            measure: uploadInfo.measure,
          })
          .then(async () => await alert("Document edited successfully"))
          .then(async () => await props.onHide)
          .then(async () => await window.location.reload(false));
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUploadInfo({
      ...uploadInfo,
      [name]: value,
    });
  };

  return (
    <Modal
      {...props}
      size="xl"
      className="admin-edit-products-modal-parent-container"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h6>Edit Product</h6>
      </Modal.Header>
      <Modal.Body>
        <Form className="admin-edit-products-modal-form">
          <div className="admin-edit-image-wrapper d-flex flex-row justify-content-between align-items-center">
            <h6>Change Image</h6>
            <Form.File
              className="admin-add-product-image-upload-field"
              id="exampleFormControlFile1"
              onChange={(event) => {
                setFile(event.target.files[0]);
                setImageDisplay(URL.createObjectURL(event.target.files[0]));
                setFileName(event.target.files[0].name);
              }}
            />
            <Button
              className="admin-upload-image-button"
              variant={"info"}
              onClick={() => handleUpload()}
            >
              Upload Image
            </Button>
            <img
              src={imageDisplay ? imageDisplay : props.imageUrl}
              width="100"
              height="100"
            />
          </div>

          <div className="admin-edit-product-name-wrapper">
            <Form.Label className="admin-edit-product-label">
              Product Name
            </Form.Label>
            <Form.Control
              className="admin-edit-product-form-name"
              defaultValue={`${props.name}`}
              type="text"
              name="name"
              placeholder="Full Product Name"
              onChange={handleChange}
            />
          </div>

          <div className="admin-edit-product-price-quantity-wrapper">
            <div>
              <Form.Label className="admin-edit-product-label">
                MRP in ₹
              </Form.Label>
              <Form.Control
                className="admin-edit-product-price"
                defaultValue={`${props.price}`}
                type="text"
                name="price"
                placeholder="MRP in ₹"
                onChange={(e) =>
                  setUploadInfo({
                    ...uploadInfo,
                    [e.target.name]: Number(e.target.value),
                  })
                }
              />
            </div>
            <div>
              <Form.Label className="admin-edit-product-label">
                Quantity
              </Form.Label>
              <Form.Control
                className="admin-edit-product-quantity"
                defaultValue={`${props.quantity}`}
                type="number"
                name="quantity"
                placeholder="Quantity eg: 1 Lt / 200 ml / 1 KG"
                onChange={(e) =>
                  setUploadInfo({
                    ...uploadInfo,
                    [e.target.name]: Number(e.target.value),
                  })
                }
              />
            </div>
            <div>
              <Form.Label>Select Measure</Form.Label>
              <Form.Control as="select" name="measure" onChange={handleChange}>
                <option selected>{props.measure}</option>
                <option>kg</option>
                <option>gm</option>
                <option>lt</option>
                <option>ml</option>
                <option>packet/s</option>
                <option>number/s</option>
              </Form.Control>
            </div>
            <div>
              <Form.Label className="admin-add-product-label">
                Items In Stock
              </Form.Label>
              <Form.Control
                className="admin-add-product-stock"
                defaultValue={`${props.availableStock}`}
                type="number"
                name="availableStock"
                placeholder="10 / 20 / 30"
                onChange={(e) =>
                  setUploadInfo({
                    ...uploadInfo,
                    [e.target.name]: Number(e.target.value),
                  })
                }
              />
            </div>

            <div>
              <Form.Label className="admin-add-product-label">
                Item GST %
              </Form.Label>
              <Form.Control
                className="admin-add-product-quantity"
                as="select"
                name="gst"
                placeholder="Item GST %"
                onChange={(e) =>
                  setUploadInfo({
                    ...uploadInfo,
                    [e.target.name]: Number(e.target.value),
                  })
                }
              >
                <option selected>{props.gst}</option>
                <option>0</option>
                <option>5</option>
                <option>12</option>
                <option>18</option>
                <option>28</option>
              </Form.Control>
            </div>
          </div>

          <div className="form-category-admin-wrapper">
            <div className="form-category-block">
              <Form.Label className="admin-edit-product-label">
                Select Category
              </Form.Label>
              <Form.Control
                className="admin-edit-product-category-select-field"
                as="select"
                name="category"
                onChange={handleChange}
              >
                <option selected>{props.category}</option>
                {props.categoryMeta.map((item, i) => (
                  <option key={i}>{item.category}</option>
                ))}
              </Form.Control>
            </div>
          </div>
          <div className="admin-text-area-group">
            <div>
              <Form.Label className="admin-edit-product-label">
                Product Description
              </Form.Label>
              <Form.Control
                className="admin-edit-product-description-area-field"
                defaultValue={`${props.description}`}
                as="textarea"
                rows="4"
                name="description"
                onChange={handleChange}
              />
            </div>
            <div>
              <Form.Label className="admin-edit-product-label">
                Expiry
              </Form.Label>
              <Form.Control
                className="admin-edit-product-expiry-field"
                defaultValue={`${props.expiry}`}
                as="textarea"
                rows="4"
                name="expiry"
                onChange={handleChange}
              />
            </div>
          </div>
        </Form>
        <Modal.Footer>
          <div className="pr-5">
            <p className="text-danger">{error}</p>
          </div>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleEditProduct()}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default AdminEditProductModal;
