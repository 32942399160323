import React, { useEffect, useContext } from "react";
import "./cat-store-product-details.styles.scss";
import Button from "@material-ui/core/Button";
import { CartContext } from "../../../contexts/cart-items/cart-items.context";

const CatStoreProductDetails = ({ productDetails }) => {
  const [cart, setCart] = useContext(CartContext);

  // effect to scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(productDetails.name?.toLowerCase());

  return (
    <div className="product-details-container">
      <div className="product-details-wrapper">
        <div className="left-container">
          <img src={productDetails.imageUrl} alt="product-details"></img>
        </div>
        <div className="right-container">
          <h1>{productDetails.name?.toLowerCase()}</h1>
          <h5>{productDetails.category}</h5>
          <p>{productDetails.description}</p>
          <div className="price-quantity">
            <span className="quantity">
              {productDetails.quantity} {productDetails.measure}
            </span>
            <span className="price">₹ {productDetails.price}</span>
          </div>
          <h5>Shelf Life : {productDetails.expiry} from manufacture date</h5>
          <div className="select-button">
            {cart.find(
              (finItem) => productDetails.productId === finItem.productId
            ) === undefined ? (
              <Button
                className="add-to-cart-button"
                size="small"
                color="primary"
                onClick={() => {
                  if (productDetails.availableStock <= 0) {
                    return alert("no stock");
                  }
                  setCart([
                    ...cart,
                    {
                      name: productDetails.name,
                      availableStock: productDetails.availableStock,
                      category: productDetails.category,
                      gst: productDetails.gst,
                      quantity: productDetails.quantity,
                      price: productDetails.price,
                      productId: productDetails.productId,
                      imageUrl: productDetails.imageUrl,
                      measure: productDetails.measure,
                      cartQuantity: 1,
                      parentStore: productDetails.parentStore,
                    },
                  ]);
                }}
              >
                Add To Cart
              </Button>
            ) : (
              <div className="quantity-buttons">
                <span
                  className="remove"
                  onClick={() => {
                    const elementsIndex = cart.findIndex(
                      (element) => element.productId == productDetails.productId
                    );
                    let editArray = [...cart];
                    if (editArray[elementsIndex].cartQuantity === 1) {
                      setCart(
                        editArray.filter(
                          (selItem) =>
                            selItem.productId !== productDetails.productId
                        )
                      );
                    } else if (editArray[elementsIndex].cartQuantity >= 1) {
                      editArray[elementsIndex] = {
                        ...editArray[elementsIndex],
                        cartQuantity: editArray[elementsIndex].cartQuantity - 1,
                      };
                      setCart(editArray);
                    }
                  }}
                >
                  -
                </span>
                <span className="value">
                  {
                    cart.find(
                      (finItem) =>
                        productDetails.productId === finItem.productId
                    ).cartQuantity
                  }
                </span>
                <span
                  className="add"
                  onClick={() => {
                    const elementsIndex = cart.findIndex(
                      (element) =>
                        element.productId === productDetails.productId
                    );

                    const availStock = cart.find(
                      (element) =>
                        element.productId === productDetails.productId
                    );
                    if (availStock.cartQuantity >= availStock.availableStock) {
                      return alert("no stock");
                    }
                    let editArray = [...cart];
                    editArray[elementsIndex] = {
                      ...editArray[elementsIndex],
                      cartQuantity: editArray[elementsIndex].cartQuantity + 1,
                    };
                    setCart(editArray);
                  }}
                >
                  +
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatStoreProductDetails;
