import React from 'react';
import { UserContext } from '../../cat-store/contexts/user-provider/UserProvider';
import CatStoreHeader from '../../cat-store/components/cat-store-front-end/cat-store-header/cat-store-header.component';
import CatStoreFooter from '../../cat-store/components/cat-store-front-end/cat-store-footer/cat-store-footer.component';
import './terms-and-conditions-page.styles.scss';

const TermasAndConditions = () => {
    let user = React.useContext(UserContext);
    // effect to scroll to top
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <CatStoreHeader user={user} />
            <div className='terms-and-conditions-main-container'>
                <h2>Terms and Conditions of Sale</h2><br />
                <p><strong>1. Definitions</strong></p>
                <p>&ldquo;Buyer&rdquo; is a person who has agreed to pay for the desired goods.</p>
                <p>&ldquo;Goods&rdquo; is all material in whole or in parts the seller is willing to supply.</p>
                <p>&ldquo;Seller&rdquo; is Kodagu Trade and Commerce Pvt. Ltd or any of its affiliates or franchises through whom the desired goods are supplied.</p>
                <p>&ldquo;Terms and Conditions&rdquo; is the understanding and contract as set out in this document by which the transaction takes place between the Seller and the Buyer.</p>
                <p>&ldquo;Contract&rdquo; is the understanding based on which the transaction takes place.</p>
                <p><strong>2. Sale or Transactions</strong></p>
                <p>2.1 Completing a transaction means that the buyer has agreed on the quoted price by the seller at the time of transaction and as seen in the website catkodagu.com or if given separately by the seller or its affiliate or franchise.</p>
                <p>2.2 Any typographical, clerical or other error or omission in any sales literature, quotation, price list, acceptance of offer, invoice or other document or information issued by the Seller shall be subject to correction without any liability on the part of the Seller.</p>
                <p>2.3 Seller does not have any returns policy or a policy on delivering goods on consignment or approval basis. However if the goods when delivered is found to be defective or damaged, the goods will be taken back with full refund of the amount paid by the buyer. Special terms and conditions may be agreed upon for business customers on a case to case basis and this shall in no way be quoted for any other transaction or for any other business.</p>
                <p><strong>3. Ordering when not available</strong></p>
                <p>3.1 Orders submitted by the buyer shall deemed to be accepted only when confirmed by the seller.</p>
                <p>3.2 Buyer shall be responsible for the accuracy of the order and shall provide all necessary information relating to the goods and agree with the time frame for delivery and the quotation given by the seller.</p>
                <p>3.3 No order shall be cancelled if the ordered goods is under transit or the production of which has begun.</p>
                <p>3.4 The seller has the right to create additional terms and conditions including making changes in the specifications, if needed for accepting the order and these additional terms and conditions shall be accepted by the buyer for fulfilment of the order.</p>
                <p>3.5 Buyer shall not cancel the order once placed and accepted by the seller.</p>
                <p><strong>4. Pricing</strong></p>
                <p>4.1 Pricing of all goods and services shall be the seller&rsquo;s prerogative. In addition the seller may add on special service charges in specific instances of delivery on a case to case basis. All goods and services delivered by the seller will be subject to statutory taxes.</p>
                <p>4.2 All prices quoted are valid only at the time of placing order and can change any time. However as the seller&rsquo;s prerogative, the prices may be frozen for a specific time under different circumstances.</p>
                <p>4.3 It is the seller&rsquo;s prerogative to include carriage charges or not to include carriage charges.</p>
                <p>4.4 The prices are always exclusive of any applicable taxes and the buyer shall be liable to pay the same to the seller.</p>
                <p><strong>5. Terms of payments</strong></p>
                <p>5.1 Other than when there may be specific special terms in writing between the seller and the buyer, the seller shall be entitled to invoice the buyer for the value of the goods at any time before or after the delivery of the goods.</p>
                <p>5.2 All invoices and proforma invoices are payable both by online means and offline means based on the credit facilitates if any have been approved by the seller. All invoices shall be settled in full and any disputes shall only be considered after the complete settlement of the invoice under consideration.</p>
                <p>5.3 If the buyer fails to make the payment on the due date, the seller shall be entitled to to take any action deemed appropriate by the seller.</p>
                <p>5.4 If the seller decides that it is appropriate to cancel the contract or take any other action on the defaulting buyer, the buyer shall indemnify the seller in full against all loss and costs that can include loss of profit, costs of labour, materials used, damages, storage and any other expenses without prejudice.</p>
                <p><strong>6. Delivery</strong></p>
                <p>6.1 Delivery of the goods is fulfilled when the buyer collects the goods from the seller&rsquo;s premises or when the seller delivers the goods at a place requested by the buyer and when agreed by the seller to do so.</p>
                <p>6.2 Any date of time quoted by the seller for delivery are approximate only and the seller shall not be liable for any delay in delivery.</p>
                <p>6.3 The seller if required may make only part delivery subject to circumstances and the seller shall not be liable in any manor for part delivery.</p>
                <p>6.4 The seller is obliged to deliver the goods only when the price of the goods have been completely paid by the buyer. If the seller fails to deliver the goods for any reason other than causes beyond the seller&rsquo;s control, the sellers liability will equal the amount received from the buyer towards the stated goods.</p>
                <p>6.5 If the buyer fails to take delivery of the goods either due to wrong instructions or any reasons beyond the buyer&rsquo;s control, the seller shall store the goods until actual delivery and may charge the buyer reasonable costs towards insurance, storage etc. It shall be the seller&rsquo;s prerogative to sell the goods at the best price obtainable if the buyer fails to take delivery of the goods within reasonable time that is determined by the seller.</p>
                <p><strong>7. Risk</strong></p>
                <p>7.1 Risk of damage or loss of goods till delivery shall be on the seller. Once the delivery has been taken, the risk of damage or loss of goods shall be on the buyer.</p>
                <p>7.2 Once the buyer has taken delivery of the goods and accepted the same, the seller shall not be obliged to take the goods back under any reasons.</p>
                <p><strong>8. Warranties and Liability</strong></p>
                <p>8.1 The seller warrants that the goods shall correspond to the specifications given subject to reasonable exceptions such as normally accepted in the trade and will be free from defects in material and workmanship at the time of delivery.</p>
                <p>8.2 The seller shall be under no liability for defect in description of the goods by the buyer.</p>
                <p>8.3 The seller shall be under no liability in respect of any defect arising from fair wear and tear, failure to follow seller&rsquo;s instructions whether oral or written, misuse, alteration or repair without the seller&rsquo;s approval.</p>
                <p>8.4 The seller shall be under no liability or obligation if the total price of the goods has not been paid by the buyer by the due date for payment.</p>
                <p>8.5 The seller is not liable or obliged to meet any warranties for goods not manufactured by the seller and the buyer shall only be entitled to the benefit of any warranty or guarantee as given by the manufacturer to the seller or directly to the buyer.</p>
                <p>8.6 When the goods are sold under the consumer transaction, the rights of the buyer shall be determined by the Consumer Transaction order 1976.</p>
                <p>8.7 Buyer shall make claims based on defect or failure to correspond with specifications within 7 (seven) days of receiving the product.</p>
                <p>8.8 Buyer shall make claims based on short supply or non delivery of specific good of the ordered goods within 24 hours after receiving the goods from the seller.</p>
                <p>8.9 Except in respect of death or personal injury caused by the seller&rsquo;s negligence outside the buyer&rsquo;s premises, the seller shall not be liable for any costs, expenses or other claims by the buyer.</p>
                <p>8.10 The seller shall not be liable to the buyer in any way for breach of contract or delay in performance of the seller&rsquo;s obligations if the causes are beyond the seller&rsquo;s reasonable control. These includes acts of god, explosion, flood, tempest, fire, accident, war or threat of war, sabotage, insurrection, civil disturbance, acts, restrictions, regulations, bye-laws, prohibitions or measures of any kind on the part of the government, parliament or local authority, embargoes, strikes, lock-outs or any other industrial action in the seller&rsquo;s premises or third party premises, power failure, breakdown in machinery or some other bona fide causes felt reasonable by the seller.</p>
                <p><strong>9. Insolvency of Buyer</strong></p>
                <p>9.1 It shall be the prerogative of the seller to continue or otherwise the contract with the buyer if the buyer becomes bankrupt or goes into liquidation, if a receiver is appointed for possession, ceases or threatens to cease carrying on business or if the seller believes for any reason which may not be disclosed that the reasons mentioned above might occur any time to the buyer.</p>
                <p><strong>10. Export</strong></p>
                <p>10.1 When goods are supplied for export from the premises of the seller, the terms and conditions for the same shall be specially agreed upon in writing between the buyer and the seller.</p>
                <p>10.2 The buyer shall be responsible for testing and inspection of the goods at the seller&rsquo;s premises before the shipment to the buyer&rsquo;s destination.</p>
                <p><strong>11. Checking on the buyer</strong></p>
                <p>11.1 The seller may analyse and assess information about the buyer among various agencies which may include information provided by the buyer or other businesses etc.</p>
                <p>11.2 The seller may monitor and/or record any phone calls the buyer may have with the seller or any of the seller&rsquo;s agents or franchise</p>
                <p><strong>12. Other</strong></p>
                <p>12.1 Any breach of contract by the buyer shall not be considered as a waiver of any subsequent breach of the same or any other provision in this contract as contained.</p>
                <p>12.2 This contract is governed by the laws of Republic of India or Bharat.</p>
                <p>12.3 Any dispute arising out of or in connection with this contract shall be settled by the courts in Bengaluru, Karnataka State, India or through arbitrators appointed in accordance with the law detailed in paragraph 12.2 above.</p>
            </div>
            <CatStoreFooter />
        </>
    )
}

export default TermasAndConditions;