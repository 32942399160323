import React, { useState, useEffect } from 'react';
import './admin-products.styles.scss';
import AdminProductCard from '../../admin-components/admin-product-card/admin-product-card.component';
import AdminAddNewProductModal from '../../admin-components/admin-add-new-product-modal/admin-add-new-product-modal.component';
import { Button } from 'react-bootstrap';
import { firestore } from '../../../../firebase/firebase.utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';

const SuperAdminProducts = ({ shopData, categoryMeta }) => {
    const [adminSearch, setAdminSearch] = useState('');
    const [modalShow, setModalShow] = useState(false);

    const filteredProducts = shopData.filter(item => {
        return item.name.toLowerCase().includes(adminSearch.toLowerCase());
    })

    return (
        <div className='admin-products-main-container'>
            <h2> <FontAwesomeIcon className='fa-icon-admin-categories' icon={faSitemap} /> PRODUCTS</h2>
            <div className='admin-products-crud-buttons'>
                <Button className='crud-button' variant={'success'} onClick={() => setModalShow(true)} > Add New Product</Button>
                <AdminAddNewProductModal show={modalShow} onHide={() => setModalShow(false)} categoryMeta={categoryMeta} />
            </div>
            <div className='admin-products-search-container'>
                <input type='search' placeholder='Search All Products' className='admin-products-search' onChange={(event) => setAdminSearch(event.target.value)} />
            </div>
            <div className='all-products-view-port'>
                {
                    filteredProducts.slice(0, 10).map((item, i) =>
                        <>
                            <AdminProductCard
                                key={i}
                                productId={item.productId}
                                name={item.name}
                                avail={item.avail}
                                description={item.description}
                                expiry={item.expiry}
                                imageUrl={item.imageUrl}
                                price={item.price}
                                quantity={item.quantity}
                                availableStock={item.availableStock}
                                gst={item.gst}
                                parentStore={item.parentStore}
                                category={item.category}
                                categoryMeta={categoryMeta}
                                addedOn={item.addedOn}
                                measure={item.measure}
                                imageName={item.imageName}
                            />
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default SuperAdminProducts;