import React from "react";
import "./cat-store-banner.styles.scss";
import MainGif from "../../../../assets/background/CAT-PROMO.gif";

const CatStoreBanner = () => {
  return (
    <div className="banner-main-container">
      {/* <img src={MainGif} alt="banner-gif" /> */}
      <div className="d-flex flex-column justify-content-center align-items-center bg-green w-100 banner-head ">
        <h1>CAT</h1>
        <h3>Commerce and Trade</h3>
        <h5>Making it closer</h5>
      </div>
    </div>
  );
};

export default CatStoreBanner;
