import React from "react";
import { Link } from "react-router-dom";
import "./cat-store-footer.styles.scss";
import { Navbar } from "react-bootstrap";
import {
  faMailBulk,
  faPhone,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainLogo from "../../../../assets/cat-store-main-logo.png";

const CatStoreFooter = () => {
  return (
    <div className="footer-main-container">
      <Navbar className="footer-nav" bg="dark">
        <Navbar.Brand className="footer-brand" href="#home">
          <img width="75px" src={MainLogo} alt="footer-logo" />
        </Navbar.Brand>
        <div className="footer-links">
          <Link to="/terms-and-conditions" style={{ textDecoration: "none" }}>
            Terms & Conditions
          </Link>
          <Link to="/privacy-policy" style={{ textDecoration: "none" }}>
            Privacy Policy
          </Link>
        </div>
        <div className="address-phone">
          <span>Kodagu Trade & Commerce Pvt. Ltd.</span>
          <span>
            <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faPhone} />
            Ph: +91-6366-922-199 | +91-6366-922-190
          </span>
          <span>
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              icon={faMailBulk}
            />
            contact@catkodagu.com
          </span>
          <span>
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              icon={faAddressBook}
            />
            No 1, Shri shambavananda complex,
            <br />
            Malbar road, Virajpet - 571219{" "}
          </span>
        </div>
      </Navbar>
      <div className="footer-bottom">
        <span>&#169; 2021-2022 Kodagu Trade & Commerce Pvt. Ltd.</span>
      </div>
    </div>
  );
};

export default CatStoreFooter;
