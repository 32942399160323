import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../contexts/user-provider/UserProvider";
import { firestore } from "../../../firebase/firebase.utils";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// All component imports go here
import CatStoreHeader from "../../components/cat-store-front-end/cat-store-header/cat-store-header.component";
import CatStoreBanner from "../../components/cat-store-front-end/cat-store-banner/cat-store-banner.component";
import CatStoreNewProducts from "../../components/cat-store-front-end/newly-added-products/newly-added-products.conponent";
import CatStoreFooter from "../../components/cat-store-front-end/cat-store-footer/cat-store-footer.component";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffff",
  },
}));

const CatStoreMainRoutes = () => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  let user = useContext(UserContext);

  // Func to set backdrops
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  // effect to fetch store data
  useEffect(() => {
    const fetchShopData = async () => {
      await setOpen(true);
      const docRef = await firestore
        .collection("online-store")
        .doc("products")
        .collection("product-list")
        .orderBy("addedOn", "desc");
      docRef
        .get()
        .then((snapshot) => {
          let data = [];
          snapshot.docs.map((doc) => data.push(doc.data()));
          setProducts(data);
          // console.log(data)
        })
        .then(() => setTimeout(() => setOpen(false), 800));
    };
    fetchShopData();
  }, []);

  return (
    <div>
      <CatStoreHeader user={user} />
      <CatStoreBanner />

      <CatStoreNewProducts products={products} />
      <CatStoreFooter />
      {/* Backdrop */}
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default CatStoreMainRoutes;
