import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./cat-store-checkout.styles.scss";
import { Button, Modal, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { firestore } from "../../../../firebase/firebase.utils";
import firebase from "firebase/app";
import "tachyons";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { CartContext } from "../../../contexts/cart-items/cart-items.context";
import axios from "axios";
import MainLogo from "../../../../assets/cat-store-main-logo.png";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffff",
  },
}));

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const CatStoreCheckout = ({ user }) => {
  const classes = useStyles();
  let history = useHistory();
  const [cart, setCart] = React.useContext(CartContext);
  const [show, setShow] = useState(false);
  const [orderIdGen, setOrderIdGen] = useState("");

  const [confirmShow, setConfirmShow] = useState(false);

  // Loader State
  const [open, setOpen] = useState(false);

  // check states for COD order
  const [codOrder, setCodOrder] = useState(false);

  // Func to set backdrops
  const handleBackdropClose = () => {
    setOpen(false);
  };
  const handleBackdropToggle = () => {
    setOpen(!open);
  };

  // Thank You modal
  const handleThankClose = () => setConfirmShow(false);
  const handleThankShow = () => setConfirmShow(true);

  // Confirm Order Modal
  const handleClose = () => setShow(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Effect to generate random code
  useEffect(() => {
    const onLoadGenRandCode = async () => {
      let randomCode = "";
      const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for (var i = 18; i > 0; --i)
        randomCode += chars[Math.floor(Math.random() * chars.length)];
      await setOrderIdGen(randomCode);
    };
    onLoadGenRandCode();
  }, []);

  const handleShow = () => {
    if (firstName === null || firstName.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (cart.length < 1) {
      alert("No items in Cart");
    } else if (lastName === null || lastName.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (addressL1 === null || addressL1.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (addressL2 === null || addressL2.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (stateName === null || stateName.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (zip === null || zip.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (phone === null || phone.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (!/^\d{10}$/.test(phone)) {
      alert("Enter a valid Phone number");
    } else if (email === null || email.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      ) === false
    ) {
      alert("Please Enter A Valid Email Id");
    } else if (tandcAccepted !== true) {
      alert("Please accept the terms & conditions of the sale");
    } else if (!/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(zip)) {
      alert("Enter a valid Pin code");
    } else {
      setShow(true);
    }
  };

  // Form Input State
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [addressL1, setAddressL1] = useState(null);
  const [addressL2, setAddressL2] = useState(null);
  const [stateName, setStateName] = useState(null);
  const [zip, setZip] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [tandcAccepted, setTandcAccepted] = useState(null);

  // Handle Confirm Order
  const handleConfirmOrder = async (data) => {
    await setOpen(true);
    await firestore
      .collection("online-store")
      .doc("online-orders")
      .collection("orders")
      .add({
        orderId: orderIdGen,
        orderItems: cart,
        customerDetails: {
          fullName: `${firstName} ${lastName}`,
          fullAddress: `${addressL1}, ${addressL2}, ${stateName}, ${zip}`,
          customerPhone: `${phone}`,
          customerEmail: `${email}`,
        },
        orderStatus: "orderPlaced",
        orderPlacedFrom: "cat kodagu",
        orderTime: firebase.firestore.FieldValue.serverTimestamp(),
        tAmount: cart
          .reduce(
            (accumalatedQuantity, item) =>
              accumalatedQuantity +
              Number(item.price) * Number(item.cartQuantity),
            0
          )
          .toFixed(2),
        paymentInfo: data ? data : "COD",
      })
      .then(async () => {
        await firestore
          .collection("users")
          .doc(`${user.uid}`)
          .collection("user-orders")
          .add({
            orderId: orderIdGen,
            orderItems: cart,
            customerDetails: {
              fullName: `${firstName} ${lastName}`,
              fullAddress: `${addressL1}, ${addressL2}, ${stateName}, ${zip}`,
              customerPhone: `${phone}`,
              customerEmail: `${email}`,
            },
            orderStatus: "orderPlaced",
            orderPlacedFrom: "cat kodagu",
            orderTime: firebase.firestore.FieldValue.serverTimestamp(),
            tAmount: cart
              .reduce(
                (accumalatedQuantity, item) =>
                  accumalatedQuantity +
                  Number(item.price) * Number(item.cartQuantity),
                0
              )
              .toFixed(2),
            paymentInfo: data ? data : "COD",
          });
      })
      .then(async () => {
        await cart.map(async (cartItem) => {
          const docRef = await firestore
            .collection("online-store")
            .doc("products")
            .collection("product-list");
          docRef.get().then((snapshot) => {
            snapshot.docs.map(async (doc) =>
              doc.data().productId === cartItem.productId
                ? await firestore
                    .collection("online-store")
                    .doc("products")
                    .collection("product-list")
                    .doc(`${doc.id}`)
                    .update({
                      availableStock: Number(
                        Number(doc.data().availableStock) -
                          Number(cartItem.cartQuantity)
                      ),
                    })
                : null
            );
          });
        });
      })
      // Send Confirmation Mail
      .then(
        async () =>
          await firestore.collection("confirmationMail").add({
            to: `${email}`,
            message: {
              subject: "Order Confirmation: Cat Kodagu",
              text: `Hello ${firstName} ${lastName} ! Thanks For Placing the Order.`,
              html: `<div style="display: flex; justify-content: center;">
                        <img src="https://catkodagu.com/static/media/cat-store-main-logo.bf2b649c.png" alt="cat-store-logo" width="80px">
                        </div>
                        <div>
                            <span>
                                <h3><b>Hello</b> ${firstName} ${lastName}!</h3> <br> Thanks For Placing the Order With Us. <br>
                            </span>
                        </div>
                        <div>
                            <span><b>Address : </b>${addressL1}, ${addressL2}, ${stateName}, ${zip}</span><br>
                            <span><b>Phone : </b>${phone}</span><br>
                            <span><b>Email : </b>${email}</span><br>
                        </div><br>
                        <div style="padding-bottom: 10px; border-bottom: 2px solid gray">
                            <h3>Items You Purchased: </h3>
                            <h3>Order ID: ${orderIdGen}</h3>
                            ${cart
                              .map(
                                (
                                  item,
                                  i
                                ) => `<span style="font-size: 1rem"><b>${
                                  i + 1
                                }.</b> <b>Name:</b> ${
                                  item.name
                                }, <b>Quantity:</b> ${item.quantity} ${
                                  item.measure
                                },
                            <b>Unit MRP:</b> ${item.price} INR X ${
                                  item.cartQuantity
                                }, <b>Store:</b> ${
                                  item.parentStore
                                }, <b>GST:</b> ${item.gst},
                            <b>Sub Total:</b> ${(
                              item.price * item.cartQuantity
                            ).toFixed(2)}</span><br>`
                              )
                              .join("")}
                        </div>
                        <h2>Grand Total: ₹ ${cart
                          .reduce(
                            (accumalatedQuantity, item) =>
                              accumalatedQuantity +
                              Number(item.price) * Number(item.cartQuantity),
                            0
                          )
                          .toFixed(2)}</h2>
                        `,
            },
          })
      )
      .then(() => setTimeout(() => setOpen(false), 500))
      .then(async () => await setConfirmShow(true));
  };

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    if (firstName === null || firstName.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (cart.length < 1) {
      alert("No items in Cart");
    } else if (lastName === null || lastName.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (addressL1 === null || addressL1.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (addressL2 === null || addressL2.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (stateName === null || stateName.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (zip === null || zip.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (phone === null || phone.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (!/^\d{10}$/.test(phone)) {
      alert("Enter a valid Phone number");
    } else if (email === null || email.length < 1) {
      alert("Fields Marked with * are Required");
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      ) === false
    ) {
      alert("Please Enter A Valid Email Id");
    } else if (tandcAccepted !== true) {
      alert("Please accept the terms & conditions of the sale");
    } else if (!/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(zip)) {
      alert("Enter a valid Pin code");
    } else {
      const result = await axios.post(
        "https://catkodagu-razorpay-server.fly.dev/payment/orders",
        {
          amount:
            cart
              .reduce(
                (accumalatedQuantity, item) =>
                  accumalatedQuantity +
                  Number(item.price) * Number(item.cartQuantity),
                0
              )
              .toFixed(2) * 100,
          receipt: orderIdGen,
        }
      );

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      const { amount, id: order_id, currency } = result.data;

      const options = {
        // key: "rzp_test_g2uHhpgpxJh3nn", // Enter the test Key ID generated from the Dashboard
        key: "rzp_live_MKN2MZx2qwv9ps", // Enter the live Key ID generated from the Dashboard
        amount: amount,
        currency: currency,
        name: "Cat Kodagu",
        description: "Online purchase",
        image: MainLogo,
        order_id: order_id,
        handler: async function (response) {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          const result = await axios.post(
            "https://catkodagu-razorpay-server.fly.dev/payment/success",
            data
          );

          if (result) {
            handleConfirmOrder(data);
          }

          // alert(result.data.msg);
        },
        prefill: {
          name: firstName,
          email: email,
          contact: phone,
        },
        notes: {
          address: addressL1,
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  }

  return (
    <>
      <div className="checkout-main-container">
        {/* Backdrop */}
        <Backdrop
          className={classes.backdrop}
          open={open}
          onClick={handleBackdropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="check-out-items-container">
          {cart.length > 0 ? (
            <Table size="md" hover scrollable responsive>
              <tbody>
                {cart.map((item, i) => (
                  <tr key={i}>
                    <td className="column-item">{i + 1}</td>
                    <td className="column-item">
                      <img src={item.imageUrl} height="75px" alt="url"></img>
                    </td>
                    <td className="column-item">{item.name}</td>
                    <td className="column-item">{item.parentStore}</td>
                    <td className="column-item">
                      {item.quantity} {item.measure}
                    </td>
                    <td className="column-item">
                      ₹{Number(item.price).toFixed(2)} X {item.cartQuantity}
                    </td>
                    <td className="column-item">
                      <div className="quantity-buttons">
                        <span
                          className="remove"
                          onClick={() => {
                            const elementsIndex = cart.findIndex(
                              (element) => element.productId == item.productId
                            );
                            let editArray = [...cart];
                            if (editArray[elementsIndex].cartQuantity === 1) {
                              setCart(
                                editArray.filter(
                                  (selItem) =>
                                    selItem.productId !== item.productId
                                )
                              );
                            } else if (
                              editArray[elementsIndex].cartQuantity >= 1
                            ) {
                              editArray[elementsIndex] = {
                                ...editArray[elementsIndex],
                                cartQuantity:
                                  editArray[elementsIndex].cartQuantity - 1,
                              };
                              setCart(editArray);
                            }
                          }}
                        >
                          -
                        </span>
                        <span className="value">
                          {
                            cart.find(
                              (finItem) => item.productId === finItem.productId
                            ).cartQuantity
                          }
                        </span>
                        <span
                          className="add"
                          onClick={() => {
                            const elementsIndex = cart.findIndex(
                              (element) => element.productId == item.productId
                            );
                            let editArray = [...cart];
                            editArray[elementsIndex] = {
                              ...editArray[elementsIndex],
                              cartQuantity:
                                editArray[elementsIndex].cartQuantity + 1,
                            };
                            setCart(editArray);
                          }}
                        >
                          +
                        </span>
                      </div>
                    </td>
                    <td className="column-item">
                      ₹
                      {(Number(item.price) * Number(item.cartQuantity)).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <span className="checkout-empty-message">No Items In Cart</span>
          )}
          <h2>
            Grand Total: ₹{" "}
            {cart
              .reduce(
                (accumalatedQuantity, item) =>
                  accumalatedQuantity +
                  Number(item.price) * Number(item.cartQuantity),
                0
              )
              .toFixed(2)}
          </h2>

          <br />

          <Button
            className="back-to-store"
            variant={"dark"}
            onClick={() => history.push("/")}
          >
            BACK TO STORE
          </Button>

          <div className="policy-shipping-information">
            <h6>Return Policy</h6>
            <p>
              The products are non-returnable. Exchange or Refund will not be
              made against a non-returnable item unless defective or is
              different from its description.
            </p>
            <h6>Shipping & Dispatch Time</h6>
            <p>
              2 working days from the date of order for orders within Bangalore.
              Other cities/towns outside Bangalore, a minimum of 5 working days
              from the date of placing your order.
            </p>
          </div>
        </div>
        {/* Info Form */}
        <div className="details-form-container">
          <div className="checkout-form-container">
            <h4>SHIPPING DETAILS</h4>
            <div className="pa4 black-80 inner-container">
              <div className="name-fields ">
                <input
                  id="fname"
                  className="input-reset ba b--black-20 pa2 mb2 db w-100"
                  type="text"
                  placeholder="First Name *"
                  required
                  onChange={(event) => setFirstName(event.target.value)}
                />

                <input
                  id="lname"
                  className="input-reset ba b--black-20 pa2 mb2 db w-100"
                  type="text"
                  placeholder="Last Name *"
                  required
                  onChange={(event) => setLastName(event.target.value)}
                />
              </div>
              <div className="address-line-1-2 ">
                {/* <label className="f5 b db mb2">Address Line 1 *</label> */}
                <input
                  id="add1"
                  className="input-reset ba b--black-20 pa2 mb2 db w-100"
                  type="text"
                  placeholder="Address Line 1 *"
                  required
                  onChange={(event) => setAddressL1(event.target.value)}
                />

                {/* <label className="f5 b db mb2">Address Line 2 *</label> */}
                <input
                  id="add2"
                  className="input-reset ba b--black-20 pa2 mb2 db w-100"
                  type="text"
                  placeholder="Address Line 2 *"
                  required
                  onChange={(event) => setAddressL2(event.target.value)}
                />
              </div>
              <div className="state-zip">
                <input
                  id="state"
                  className="input-reset ba b--black-20 pa2 mb2 db w-100"
                  type="text"
                  placeholder="State *"
                  required
                  onChange={(event) => setStateName(event.target.value)}
                />

                <input
                  id="zip"
                  className="input-reset ba b--black-20 pa2 mb2 db w-100"
                  type="text"
                  placeholder="Postcode / ZIP *"
                  required
                  onChange={(event) => setZip(event.target.value)}
                />
              </div>
              <div className="contact-details">
                <input
                  id="phone"
                  className="input-reset ba b--black-20 pa2 mb2 db w-100"
                  type="text"
                  placeholder="Phone *"
                  required
                  onChange={(event) => setPhone(event.target.value)}
                />

                <input
                  id="email"
                  className="input-reset ba b--black-20 pa2 mb2 db w-100"
                  type="email"
                  placeholder="Email *"
                  required
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>

              <Form.Group
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  paddingTop: "10px",
                  display: "flex",
                }}
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  onChange={(event) => setTandcAccepted(event.target.checked)}
                  required
                />
                <p>
                  I have read, understood and accepted the
                  <Link to="/terms-and-conditions">
                    'Terms and Conditions'
                  </Link>{" "}
                  of this sale.
                </p>
              </Form.Group>
            </div>

            {/* Confirm Order Modal */}
            <Modal
              show={show}
              onHide={handleClose}
              animation={true}
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <h4>Confirm Order</h4>
              </Modal.Header>
              <Modal.Body>
                <h4>Confirm Shipping & Contact Details</h4>
                <h6>Full Name</h6>
                <p>
                  {firstName} {lastName}
                </p>
                <h6>Full Shipping Address</h6>
                <span>{addressL1},</span>
                <br />
                <span>{addressL2}</span>
                <br />
                <span>
                  {stateName}, {zip}
                </span>
                <br />
                <br />
                <h6>Contact information</h6>
                <p>
                  <b>Phone :</b> {phone}
                </p>
                <p>
                  <b>Email :</b> {email}
                </p>
                <br />
                <p>
                  <b>Payment Mode :</b> COD
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Go Back
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleClose();
                    handleConfirmOrder();
                  }}
                >
                  Place Order
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Thanks for the order modal */}
            <Modal
              show={confirmShow}
              onHide={handleClose}
              centered
              backdrop="static"
              keyboard={false}
            >
              <Modal.Body>
                <h3>
                  Thank You For Your Order. Please Check you email for Order
                  Confirmation.
                </h3>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleThankClose} href="/">
                  Go Back Home
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <Form.Group
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              paddingRight: "50px",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
            controlId="formBasicCheckbox"
          >
            <Form.Check
              type="checkbox"
              onChange={(event) => setCodOrder(event.target.checked)}
            />
            <p>I want to pay on Delivery (COD Order)</p>
          </Form.Group>
          <div className="d-flex justify-content-center">
            {codOrder ? (
              <Button
                className="place-order-button"
                variant={"success"}
                onClick={handleShow}
              >
                Confirm Order
              </Button>
            ) : (
              <Button
                className="place-order-button "
                type="submit"
                variant={"info"}
                onClick={displayRazorpay}
              >
                Confirm Order & Pay Online
              </Button>
            )}
          </div>
        </div>
      </div>
      {/* Thanks for the order modal */}
      <Modal
        show={confirmShow}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h3>
            Thank You For Your Order. Please Check you email for Order
            Confirmation.
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleThankClose} href="/">
            Go Back Home
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CatStoreCheckout;
