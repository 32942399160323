import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import './cat-store-payment-status.styles.scss';
import { Tab, Tabs, Table, Spinner, Button } from "react-bootstrap";
import { decrypt } from "../../../security-algorithms/security-algorithms";
import SuccessGif from '../../../../assets/success-gif.gif';
import FailureGif from '../../../../assets/failure-gif.gif';

const CatStorePaymentStatus = ({ user }) => {
    const history = useHistory();
    const [recievedStatus, setRecievedStatus] = useState("");

    // Effect to decode url params
    useEffect(() => {
        const decodeUrlParams = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const i = queryParams.get('i');
            let decryptedText = decrypt(i);
            let convertedtext = JSON.parse('{"' + decryptedText.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
            setRecievedStatus(convertedtext)
        };
        decodeUrlParams();
    }, [])

    return (
        <>
            {user ?
                <div className="cat-store-pay-status-container">
                    {recievedStatus.STC === "000" ?
                        <div className="successful-transaction">
                            <img src={SuccessGif} width="300px" alt="trxn-success" />
                            <h2>SUCCESS</h2>
                            <h4>Transaction Amount : ₹ {Number(recievedStatus.AMT).toFixed(2)}</h4>
                            <h4>Transaction Reference # {recievedStatus.BRN}</h4><br />
                            <Button variant="success" size="sm" onClick={() => history.push("/")} >Go Back Home</Button>
                        </div>
                        :
                        <div className="failed-transaction">
                            <img src={FailureGif} width="300px" alt="trxn-failed" />
                            <h2>FAILED</h2>
                            <h4>Transaction Amount : ₹ {Number(recievedStatus.AMT).toFixed(2)}</h4>
                            <h4>Transaction Reference # {recievedStatus.BRN}</h4><br />
                            <Button variant="danger" size="sm" onClick={() => history.push("/")} >Go Back Home</Button>
                        </div>
                    }
                </div> :
                <div className="cat-store-pay-status-container" style={{ justifyContent: "center" }}>
                    <Spinner animation="border" />
                </div>
            }
        </>
    )
}

export default CatStorePaymentStatus;
